const filterLessonFields = ['userId', 'lastModifiedDate', 'cosLessonVersion'];
const filterExerciseFields = ['cosExerciseVersion'];
const filterInquiryFields = ['minCorrectScore'];

/**
 * Function which is responsible for removing currently not needed/wanted attributes from
 * server response on all hierarchy levels (lesson->exercise->inquiry). Those attributes
 * are provided from the server due to the current data model.
 *
 * @param payload - the server response with lesson progress data
 * @returns {*&{exerciseProgresses}}
 */
export const filterDataFromService = payload => {
  const filteredLessonData = removeObjectAttributes(payload, filterLessonFields);
  const { exerciseProgresses } = filteredLessonData;
  const filteredExerciseProgresses = filterExerciseData(exerciseProgresses);

  return { ...filteredLessonData, exerciseProgresses: filteredExerciseProgresses };
};

const filterExerciseData = exerciseProgresses => {
  return exerciseProgresses.map(exerciseProgress => {
    const filteredExerciseProgress = removeObjectAttributes(exerciseProgress, filterExerciseFields);
    const { inquiryProgresses } = filteredExerciseProgress;
    const filteredInquiryProgresses = filterInquiryData(inquiryProgresses);

    return { ...filteredExerciseProgress, inquiryProgresses: filteredInquiryProgresses };
  });
};

const filterInquiryData = inquiryProgresses => {
  return inquiryProgresses.map(inquiryProgress =>
    removeObjectAttributes(inquiryProgress, filterInquiryFields),
  );
};

const removeObjectAttributes = (sourceObject, attributesToRemoveArray) => {
  const targetObject = { ...sourceObject };
  attributesToRemoveArray.forEach(field => {
    if (field in targetObject) {
      delete targetObject[field];
    }
  });
  return targetObject;
};
