export const PAGE_TYPES = Object.freeze({
  HELP: 'HELP',
  ARTICLE: 'ARTICLE',
  CONSENT: 'CONSENT',
  VOCABULARY: 'VOCABULARY',
  ALPHABETICAL_ORDER: 'ALPHABETICAL_ORDER',
  LEGAL: 'LEGAL',
  COURSE: 'COURSE',
  LESSON: 'LESSON',
  EXERCISE: 'EXERCISE',
  LESSON_EXERCISE_RESULT: 'LESSON_EXERCISE_RESULT',
  LESSON_SUMMARY: 'LESSON_SUMMARY',
  GRAMMAR: 'GRAMMAR',
  GRAMMAR_DETAIL: 'GRAMMAR_DETAIL',
  GRAMMAR_OVERVIEW: 'GRAMMAR_OVERVIEW',
  VOCABULARY_OVERVIEW: 'VOCABULARY_OVERVIEW',
  REGIONAL_STUDIES: 'REGIONAL_STUDIES',
  PLACEMENT_TEST: 'PLACEMENT_TEST',
  FINAL_TEST: 'FINAL_TEST',
  MANUSCRIPT: 'MANUSCRIPT',
  USER_PROFILE: 'USER_PROFILE',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_SET: 'PASSWORD_SET',
  REGISTER_USER: 'REGISTER_USER',
  FEEDBACK_STATUS: 'FEEDBACK_STATUS',
  EXTRAS: 'EXTRAS',
  PLACEMENT_DASHBOARD: 'PLACEMENT_DASHBOARD',
  VOCABULARY_TRAINER: 'VOCABULARY_TRAINER',
  NOT_FOUND_PAGE: 'NOT_FOUND',
  LOGIN: 'LOGIN',
});

export const LG_NAVIGATION_TYPES = Object.freeze({
  COURSE: 'COURSE',
  RECENT_CONTENTS: 'RECENT_CONTENTS',
  DASHBOARD: 'DASHBOARD',
});

export const GTM_LG_NAVIGATION_TYPE = Object.freeze({
  [LG_NAVIGATION_TYPES.COURSE]: 'Kurs',
  [LG_NAVIGATION_TYPES.RECENT_CONTENTS]: 'Aktuelle Inhalte',
  [LG_NAVIGATION_TYPES.DASHBOARD]: 'Dashboard',
});

export const GTM_NO_CONTENT_PAGES = Object.freeze({
  [PAGE_TYPES.GRAMMAR_OVERVIEW]: 'Grammatik Übersicht',
  [PAGE_TYPES.VOCABULARY_OVERVIEW]: 'Grundwortschatz',
  [PAGE_TYPES.VOCABULARY_TRAINER]: 'Vokabeltrainer',
  [PAGE_TYPES.REGISTER_USER]: 'Registrierung',
  [PAGE_TYPES.USER_PROFILE]: 'Profil',
  [PAGE_TYPES.PASSWORD_CHANGE]: 'Passwort ändern',
  [PAGE_TYPES.PASSWORD_RESET]: 'Passwort zurücksetzen',
  [PAGE_TYPES.PASSWORD_SET]: 'Passwort setzen',
  [PAGE_TYPES.FEEDBACK_STATUS]: 'Feedback Status',
  [PAGE_TYPES.NOT_FOUND_PAGE]: 'Error 404',
  [PAGE_TYPES.LOGIN]: 'Login',
});

export const GTM_USER_RELATED_PAGES = [
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.REGISTER_USER],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.USER_PROFILE],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_CHANGE],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_RESET],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_SET],
];
export const GTM_USER_RELATED_CATEGORY_NAME = 'Nutzerprofil';

export const GTM_TECHNICAL_PAGES = [
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.REGISTER_USER],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.USER_PROFILE],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_CHANGE],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_RESET],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_SET],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.FEEDBACK_STATUS],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.NOT_FOUND_PAGE],
  GTM_NO_CONTENT_PAGES[PAGE_TYPES.LOGIN],
];

export const GTM_NO_CONTENT_PAGE_IDS = Object.freeze({
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.GRAMMAR_OVERVIEW]]: 5002,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.VOCABULARY_OVERVIEW]]: 5003,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.VOCABULARY_TRAINER]]: 5004,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.REGISTER_USER]]: 5005,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.USER_PROFILE]]: 5006,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_CHANGE]]: 5007,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_RESET]]: 5008,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.PASSWORD_SET]]: 5009,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.FEEDBACK_STATUS]]: 5010,
  [GTM_NO_CONTENT_PAGES[PAGE_TYPES.NOT_FOUND_PAGE]]: 5011,
});

export const GTM_LESSON_PARTS = Object.freeze({
  [PAGE_TYPES.VOCABULARY]: 'Wortschatz',
  [PAGE_TYPES.GRAMMAR]: 'Grammatik',
  [PAGE_TYPES.REGIONAL_STUDIES]: 'Landeskunde',
  [PAGE_TYPES.MANUSCRIPT]: 'Manuskript',
  [PAGE_TYPES.EXTRAS]: 'Extras',
  [PAGE_TYPES.ALPHABETICAL_ORDER]: 'Lauttabelle',
});
