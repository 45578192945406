import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userIsLoggedIn } from '../state/user/userSelectors';
import { syncLessonProgress } from '../state/progress/learnProgressDuck';
import { isLessonProgressSyncRequiredSelector } from '../state/progress/learnProgressSelectors';

export const useSyncLearnProgress = () => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const isLessonProgressSyncRequired = useSelector(isLessonProgressSyncRequiredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && isLessonProgressSyncRequired) {
      dispatch(syncLessonProgress());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
};
