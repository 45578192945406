import { useI18nContext } from '../context/I18nContext.jsx';

const isPlainKey = translation => typeof translation === 'string' || !translation;

const normalizeTranslation = translation =>
  isPlainKey(translation) ? { key: translation, parameters: {} } : translation;

export const useTranslation = translation => {
  const { i18n } = useI18nContext();
  const { key, parameters } = normalizeTranslation(translation);
  if (!key) {
    return null;
  }
  return i18n.t(key, parameters);
};
