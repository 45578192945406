import styled from 'styled-components';
import { colors, fontSize } from '../../utils/css';

export const KickerInfo = styled('span')`
  ${fontSize.minus1}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_03 : colors.BLUE_GREY_04)};
  line-height: 1.1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const DateTimeInfo = styled('span')`
  ${fontSize.minus1}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_03 : colors.BLUE_GREY_04)};
  line-height: 1.1;
`;

export const CopyrightInfo = styled('span')`
  ${fontSize.minus1}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_03 : colors.BLUE_GREY_04)};
  line-height: 1.1;
  font-style: italic;
`;
