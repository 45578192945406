import styled, { css } from 'styled-components';
import { colors, fontSize } from '../../utils/css';

export const NavigationLinkInteraction = styled('span')`
  ${fontSize.base}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const PageLinkInteraction = styled('span')`
  ${fontSize.base}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const richtextLinkInteractionStyles = css`
  ${fontSize.base}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: underline;
`;

export const ButtonInteraction = styled('button')`
  ${fontSize.base}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1rem;
  font-weight: 600;
  text-align: center;
`;

export const FooterLinkInteraction = styled('span')`
  ${fontSize.minus1}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
