import { createContext, useContext } from 'react';
import { getLessonStructuralInfo } from '../utils/lessonUtils';

const contextFactory = structuralData => ({
  get structuralData() {
    if (!structuralData) return {};

    const {
      id,
      name,
      title,
      language,
      namedUrl: contentURL,
      __typename,
      contentLinks,
      dkLearningLevel,
      isPlacementTestCourse,
      isFinalTest,
      lessonId,
      lessonName,
      lessonPath,
      lessonExerciseOverviewItems,
    } = structuralData;
    const isFromPlacementCourse = isPlacementTestCourse;

    const { lessonGroupNumber, lessonNumberInLessonGroup, lessonNumber } = getLessonStructuralInfo({
      contentLinks,
      lessonId,
    });

    return {
      lessonId,
      lessonParentId: id,
      lessonParentName: __typename === 'Course' ? title : name,
      lessonParentType: __typename,
      language,
      dkLearningLevel,
      contentURL,
      isFromPlacementCourse,
      isFinalTest,
      lessonGroupNumber,
      lessonNumberInLessonGroup,
      lessonNumber,
      lessonName,
      lessonPath,
      lessonExerciseOverviewItems,
    };
  },
});

export const StructuralLessonContext = createContext(contextFactory());

export const useStructuralLessonData = () => useContext(StructuralLessonContext);

export const StructuralLessonContextProvider = ({ structuralData, children }) => (
  <StructuralLessonContext.Provider value={contextFactory(structuralData)}>
    {children}
  </StructuralLessonContext.Provider>
);
