import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { checkUserProfile } from '../services/userService';
import { loginTimeout } from '../state/user/userDuck';
import { userIsLoggedIn } from '../state/user/userSelectors';
import { FEEDBACK_TYPES } from '../constants/feedback';
import { getFeedbackUrl } from '../utils/url/urlFactory';

export const useCheckSessionTimeout = langCode => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(userIsLoggedIn);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      checkUserProfile()
        .then(response => {
          if (response.status === 401) {
            dispatch(loginTimeout());
            history.push(getFeedbackUrl(FEEDBACK_TYPES.SESSION_TIMEOUT, langCode));
          }
        })
        .catch(() => {
          history.push(getFeedbackUrl(FEEDBACK_TYPES.OTHER, langCode));
        });
    }
  }, [dispatch, history, isLoggedIn, langCode]);
};
