const possibleTypes = {
  ModelAspect: [
    'Exercise',
    'Knowledge',
    'Article',
    'Category',
    'Course',
    'Subject',
    'GeographicLocation',
    'Lesson',
    'Navigation',
    'Person',
    'Image',
    'Audio',
    'Video',
    'Widget',
    'Livestream',
    'Download',
    'Keyword',
    'ImageGallery',
    'GalleryImage',
    'GeographicRegion',
    'ExternalLink',
    'PhysicalImage',
    'ContentComposition',
    'InformationSpace',
    'CompositionComponent',
    'CompositionComponentConfiguration',
    'PrivacySettings',
  ],
  DeliveryAspect: [
    'Exercise',
    'Knowledge',
    'Article',
    'Category',
    'Course',
    'Lesson',
    'Navigation',
    'Person',
    'Image',
    'Audio',
    'Video',
    'Widget',
    'ImageGallery',
    'GeographicRegion',
    'ContentComposition',
    'PrivacySettings',
  ],
  NamedAspect: [
    'Exercise',
    'Knowledge',
    'Article',
    'Category',
    'Course',
    'Subject',
    'GeographicLocation',
    'Lesson',
    'Navigation',
    'Person',
    'Image',
    'Audio',
    'Video',
    'Widget',
    'Livestream',
    'Download',
    'Keyword',
    'ImageGallery',
    'GalleryImage',
    'GeographicRegion',
    'ExternalLink',
    'ContentComposition',
    'InformationSpace',
    'PrivacySettings',
  ],
  AssociationsAspect: [
    'Exercise',
    'Knowledge',
    'Article',
    'Course',
    'Lesson',
    'Navigation',
    'Person',
    'Image',
    'Audio',
    'Video',
    'Widget',
    'ImageGallery',
    'GalleryImage',
    'PrivacySettings',
  ],
  TextualAspect: ['Article', 'Person', 'Audio', 'Video', 'ImageGallery'],
  ContactAspect: ['Article', 'Person', 'Audio', 'Video', 'ImageGallery'],
  MetadataAspect: ['Article', 'Audio', 'Video', 'ImageGallery'],
  DkMetadataAspect: ['Article'],
  GeographicLocationAspect: ['GeographicLocation'],
  TreeAspect: ['Category', 'Navigation', 'GeographicRegion'],
  DeletionAspect: ['Image', 'Audio', 'Video'],
  PlaybackResourceAspect: ['Audio', 'Video'],
  BinaryDataAspect: ['Download', 'PhysicalImage'],
  SocialMediaAspect: ['Article', 'Audio', 'Video', 'ImageGallery', 'PrivacySettings'],
  FooterAspect: [
    'Article',
    'Navigation',
    'Person',
    'Audio',
    'Video',
    'ImageGallery',
    'PrivacySettings',
  ],
  BetaLayerAspect: [
    'Article',
    'Navigation',
    'Person',
    'Audio',
    'Video',
    'ImageGallery',
    'PrivacySettings',
  ],
  UrlAspect: [
    'Article',
    'Navigation',
    'Person',
    'Audio',
    'Video',
    'ImageGallery',
    'PrivacySettings',
  ],
  PersonRelatedContent: ['Article', 'Audio', 'Video', 'Image', 'ImageGallery'],
  Content: [
    'Article',
    'Category',
    'Subject',
    'GeographicLocation',
    'Navigation',
    'Person',
    'Image',
    'Audio',
    'Video',
    'Widget',
    'Livestream',
    'Download',
    'Keyword',
    'ImageGallery',
    'GalleryImage',
    'GeographicRegion',
    'CocoContent',
    'PrivacySettings',
  ],
};

function equalVocabItem(readField, item) {
  return otherItem =>
    readField('text', item) === readField('text', otherItem) &&
    readField('subTitle', item) === readField('subTitle', otherItem) &&
    readField('name', item) === readField('name', otherItem);
}

/** @type {import("@apollo/client").InMemoryCacheConfig} */
export const cacheOptions = {
  possibleTypes,
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        // IMPORTANT: This will only work with the vocabulary search
        findContents: {
          keyArgs: ['lang', 'must', ['value', 'field'], 'sort'],
          merge: (existing, incoming, { readField }) => {
            const result = {
              ...incoming,
              hits: existing ? existing.hits.slice(0) : [],
            };

            incoming.hits.forEach(item => {
              if (!result.hits.some(equalVocabItem(readField, item))) {
                result.hits.push(item);
              }
            });

            return result;
          },
        },
      },
    },
  },
};

// fetch(`http://localhost:4000/graphql`, {
//   method: 'POST',
//   headers: { 'Content-Type': 'application/json' },
//   body: JSON.stringify({
//     variables: {},
//     query: `
//    {
//      __schema {
//        types {
//          kind
//          name
//          possibleTypes {
//            name
//          }
//        }
//      }
//    }
//     `,
//   }),
// })
//   .then(result => result.json())
//   .then(result => {
//     const possibleTypes = {};

//     result.data.__schema.types.forEach(supertype => {
//       if (supertype.possibleTypes) {
//         possibleTypes[supertype.name] =
//           supertype.possibleTypes.map(subtype => subtype.name);
//       }
//     });
//     console.log(JSON.stringify(possibleTypes));
//   });
