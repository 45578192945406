import { LANG_CODES } from '../../../../../utils/mappers/langMapper';
import {
  CHINESE_SIMP_TRANSLATION_KEY,
  CHINESE_TRAD_TRANSLATION_KEY,
} from '../../../../../constants/menuLanguageCodes';

export const isLanguageSelected = ({
  languageTranslationKey,
  currentLanguage,
  isTraditionalChin,
}) => {
  const isCurrentLanguageChinese = currentLanguage === LANG_CODES.CHINESE;
  if (isCurrentLanguageChinese) {
    return (
      (isTraditionalChin && languageTranslationKey === CHINESE_TRAD_TRANSLATION_KEY) ||
      (!isTraditionalChin && languageTranslationKey === CHINESE_SIMP_TRANSLATION_KEY)
    );
  }
  return languageTranslationKey === currentLanguage;
};
