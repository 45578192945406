import NotoSansLatinBasicLettersRegular from './NotoSansLatinBasicLetters/NotoSansLatinBasicLetters-Regular.woff2';
import NotoSansLatinBasicLettersBold from './NotoSansLatinBasicLetters/NotoSansLatinBasicLetters-Bold.woff2';
import NotoSansLatinBasicLettersItalic from './NotoSansLatinBasicLetters/NotoSansLatinBasicLetters-Italic.woff2';
import NotoSansLatinBasicLettersBoldItalic from './NotoSansLatinBasicLetters/NotoSansLatinBasicLetters-BoldItalic.woff2';

import NotoSansLatinBasicSymbolsRegular from './NotoSansLatinBasicSymbols/NotoSansLatinBasicSymbols-Regular.woff2';
import NotoSansLatinBasicSymbolsBold from './NotoSansLatinBasicSymbols/NotoSansLatinBasicSymbols-Bold.woff2';
import NotoSansLatinBasicSymbolsItalic from './NotoSansLatinBasicSymbols/NotoSansLatinBasicSymbols-Italic.woff2';
import NotoSansLatinBasicSymbolsBoldItalic from './NotoSansLatinBasicSymbols/NotoSansLatinBasicSymbols-BoldItalic.woff2';

import NotoSansLatinExtendedRegular from './NotoSansLatinExtended/NotoSansLatinExtended-Regular.woff2';
import NotoSansLatinExtendedBold from './NotoSansLatinExtended/NotoSansLatinExtended-Bold.woff2';
import NotoSansLatinExtendedItalic from './NotoSansLatinExtended/NotoSansLatinExtended-Italic.woff2';
import NotoSansLatinExtendedBoldItalic from './NotoSansLatinExtended/NotoSansLatinExtended-BoldItalic.woff2';

import NotoSansGreekRegular from './NotoSansGreek/NotoSansGreek-Regular.woff2';
import NotoSansGreekBold from './NotoSansGreek/NotoSansGreek-Bold.woff2';
import NotoSansGreekItalic from './NotoSansGreek/NotoSansGreek-Italic.woff2';
import NotoSansGreekBoldItalic from './NotoSansGreek/NotoSansGreek-BoldItalic.woff2';

import NotoSansCyrillicRegular from './NotoSansCyrillic/NotoSansCyrillic-Regular.woff2';
import NotoSansCyrillicBold from './NotoSansCyrillic/NotoSansCyrillic-Bold.woff2';
import NotoSansCyrillicItalic from './NotoSansCyrillic/NotoSansCyrillic-Italic.woff2';
import NotoSansCyrillicBoldItalic from './NotoSansCyrillic/NotoSansCyrillic-BoldItalic.woff2';

import NotoSansEthiopicLight from './NotoSansEthiopic/NotoSansEthiopic-Light.woff2';
import NotoSansEthiopicMedium from './NotoSansEthiopic/NotoSansEthiopic-Medium.woff2';

import NotoNaskhArabicRegular from './NotoNaskhArabic/NotoNaskhArabic-Regular.woff2';
import NotoNaskhArabicBold from './NotoNaskhArabic/NotoNaskhArabic-Bold.woff2';

import NotoSansBengaliLight from './NotoSansBengali/NotoSansBengali-Light.woff2';
import NotoSansBengaliMedium from './NotoSansBengali/NotoSansBengali-Medium.woff2';

import NotoSansDevanagariLight from './NotoSansDevanagari/NotoSansDevanagari-Light.woff2';
import NotoSansDevanagariMedium from './NotoSansDevanagari/NotoSansDevanagari-Medium.woff2';

export const NotoSansLatinBasicLetters = {
  regular: NotoSansLatinBasicLettersRegular,
  bold: NotoSansLatinBasicLettersBold,
  italic: NotoSansLatinBasicLettersItalic,
  boldItalic: NotoSansLatinBasicLettersBoldItalic,
};
export const NotoSansLatinBasicSymbols = {
  regular: NotoSansLatinBasicSymbolsRegular,
  bold: NotoSansLatinBasicSymbolsBold,
  italic: NotoSansLatinBasicSymbolsItalic,
  boldItalic: NotoSansLatinBasicSymbolsBoldItalic,
};
export const NotoSansLatinExtended = {
  regular: NotoSansLatinExtendedRegular,
  bold: NotoSansLatinExtendedBold,
  italic: NotoSansLatinExtendedItalic,
  boldItalic: NotoSansLatinExtendedBoldItalic,
};
export const NotoSansGreek = {
  regular: NotoSansGreekRegular,
  bold: NotoSansGreekBold,
  italic: NotoSansGreekItalic,
  boldItalic: NotoSansGreekBoldItalic,
};
export const NotoSansCyrillic = {
  regular: NotoSansCyrillicRegular,
  bold: NotoSansCyrillicBold,
  italic: NotoSansCyrillicItalic,
  boldItalic: NotoSansCyrillicBoldItalic,
};
export const NotoSansEthiopic = {
  regular: NotoSansEthiopicLight,
  bold: NotoSansEthiopicMedium,
};
export const NotoNaskhArabic = {
  regular: NotoNaskhArabicRegular,
  bold: NotoNaskhArabicBold,
};
export const NotoSansBengali = {
  regular: NotoSansBengaliLight,
  bold: NotoSansBengaliMedium,
};
export const NotoSansDevanagari = {
  regular: NotoSansDevanagariLight,
  bold: NotoSansDevanagariMedium,
};
