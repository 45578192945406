/* @used in ROAD */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFrontendConfig } from '../../context/FrontendConfigContext';
import { useGlobalsContext } from '../../context/GlobalsContext';

let loadingForConsentID = null;

const loadCmpScript = async ({ consentManagerId, footer }) => {
  const [{ default: customCmpStyles }, { initCmpScript }] = await Promise.all([
    // need to import with inline option because we deal with shadow dom that makes things harder ;)
    // we should not inject css into the page, but only into the correct shadow root
    // eslint-disable-next-line import/no-unresolved
    import('./cmp-style-overwrite.css?inline'),
    import('../../utils/consentScript'),
  ]);
  initCmpScript({ consentManagerId, customCmpStyles, footer });
};

const loadCmpScriptOnlyOnce = ({ consentManagerId, footer }) => {
  if (consentManagerId !== loadingForConsentID) {
    loadCmpScript({ consentManagerId, footer });
    loadingForConsentID = consentManagerId;
  }
};

export const ConsentManagement = ({ data: { footer } }) => {
  const { consentManagerId } = useFrontendConfig();
  const { document } = useGlobalsContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (consentManagerId) {
      loadCmpScriptOnlyOnce({ consentManagerId, footer });
    }
  }, [consentManagerId, footer]);

  useEffect(
    () => () => {
      document.querySelectorAll('script[data-cmp-haspreview]').forEach(elem => {
        elem.removeAttribute('data-cmp-preview-connect');
        elem.removeAttribute('data-cmp-preview-id');
        elem.removeAttribute('data-cmp-haspreview');
      });
    },
    [pathname, document],
  );

  return null;
};
