import PangeaLatinBasicLettersAll from './DWPangeaLatinBasicLettersVAR.woff2';

import PangeaLatinBasicSymbolsAll from './DWPangeaLatinBasicSymbolsVAR.woff2';

import PangeaLatinExtendedAll from './DWPangeaLatinExtendedVAR.woff2';

import PangeaGreekAll from './DWPangeaGreekVAR.woff2';

import PangeaCyrillicAll from './DWPangeaCyrillicVAR.woff2';

import PangeaArabicAll from './DWPangeaArabicVAR.woff2';

export const PangeaLatinBasicLetters = {
  all: PangeaLatinBasicLettersAll,
};

export const PangeaLatinBasicSymbols = {
  all: PangeaLatinBasicSymbolsAll,
};

export const PangeaLatinExtended = {
  all: PangeaLatinExtendedAll,
};

export const PangeaGreek = {
  all: PangeaGreekAll,
};

export const PangeaCyrillic = {
  all: PangeaCyrillicAll,
};

export const PangeaArabic = {
  all: PangeaArabicAll,
};
