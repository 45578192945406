import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { colors } from '../../utils/css';
import { keyCodes } from '../../constants/keyCodes';
import { positionOf, getZIndex } from './helpers';
import { flyClassName, flyingFocusId, focusTargetClassName } from './constants';
import { useGlobalsContext } from '../../context/GlobalsContext';

const duration = 250;

const { TAB, ENTER, SPACEBAR, ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN } = keyCodes;

const refreshEventName = 'refreshFlyingFocus';

// @used in ROAD
export const FlyingFocus = ({ className }) => {
  const ref = useRef();

  // Hint - additional variable for visibility seems to be needed,
  // when using `focusedTarget` directly - focus box is not measured properly.
  // Maybe there is a way to improve here - so try if you have time ;)
  const [visible, setVisible] = useState(false);
  const [focusedTarget, setFocusedTarget] = useState(null);
  // this variable is here just to toggle it when you want to force a new render
  const [forceUpdate, setForceUpdate] = useState(false);
  const prevFocusedRef = useRef();

  const { documentElement } = useGlobalsContext().document;

  const hideAndClearTarget = useCallback(() => {
    setVisible(false);
    if (prevFocusedRef.current) {
      prevFocusedRef.current.classList.remove(focusTargetClassName);
      prevFocusedRef.current = null;
    }
  }, []);

  useEffect(() => {
    let isKeyboardMode = false;
    let animationTimeoutHandleId = null;

    const moveFocusOnTargetChange = target => {
      hideAndClearTarget();
      target.classList.add(focusTargetClassName);
      setFocusedTarget(target);
      setVisible(true);
      prevFocusedRef.current = target;
    };

    const moveFocusAfterAnimationOnTargetChange = target => {
      clearTimeout(animationTimeoutHandleId);
      animationTimeoutHandleId = setTimeout(() => {
        moveFocusOnTargetChange(target);
      }, target?.dataset?.focusdelay || 450);
    };

    // --- LISTENERS ----

    const startKeyboardModeOnSelectedKeysListener = event => {
      const { key } = event;
      if ([TAB, ENTER, SPACEBAR, ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN].includes(key)) {
        isKeyboardMode = true;
      }
    };

    const dispatchFlyingFocusRefresh = (options = { delay: 0 }) => {
      documentElement.dispatchEvent(new CustomEvent(refreshEventName, { detail: options }));
    };

    const buttonFocusPositionRefreshListener = event => {
      const { key, target } = event;
      if ([ENTER, SPACEBAR].includes(key)) {
        // looks like for buttons refresh is just enough
        // but each button might trigger UI change with different animation times
        dispatchFlyingFocusRefresh({ delay: target?.dataset?.focusdelay || 450 });
      }
    };

    const onFocusEndListener = () => {
      isKeyboardMode = false;
      clearTimeout(animationTimeoutHandleId);
      if (prevFocusedRef.current) {
        prevFocusedRef.current.removeEventListener(
          'keydown',
          buttonFocusPositionRefreshListener,
          true,
        );
      }
      hideAndClearTarget();
    };

    const onFocusListener = event => {
      const { target } = event;
      if (!isKeyboardMode) {
        return;
      }
      clearTimeout(animationTimeoutHandleId);
      moveFocusOnTargetChange(target);

      const eventPath = event?.path || event?.composedPath();
      if (eventPath?.find(elem => elem.classList?.contains('video-js'))) {
        // videojs elements might appear / disappear so just a refresh is not enough
        moveFocusAfterAnimationOnTargetChange(target);
      }

      // Watch out, buttons can change UI state in a way
      // that moves the button itself (e.g. vita on person page)
      if (target.tagName === 'button') {
        target.addEventListener('keydown', buttonFocusPositionRefreshListener, true);
      }
    };

    documentElement.addEventListener('keydown', startKeyboardModeOnSelectedKeysListener, true);

    documentElement.addEventListener('mousedown', onFocusEndListener, true);
    documentElement.addEventListener('touchstart', onFocusEndListener, true);

    documentElement.addEventListener('blur', hideAndClearTarget, true);

    documentElement.addEventListener('focus', onFocusListener, true);

    return () => {
      onFocusEndListener();
      setFocusedTarget(null);
      documentElement.removeEventListener('keydown', startKeyboardModeOnSelectedKeysListener, true);

      documentElement.removeEventListener('mousedown', onFocusEndListener, true);
      documentElement.removeEventListener('touchstart', onFocusEndListener, true);

      documentElement.removeEventListener('blur', hideAndClearTarget, true);

      documentElement.removeEventListener('focus', onFocusListener, true);
    };
  }, [documentElement, hideAndClearTarget]);

  useEffect(() => {
    const forceUpdateListener = event => {
      const { delay } = event.detail;
      // no need to clear timeout as it will only result in one more re-render
      // but it will not change the component state e.g. reset focused target
      // or capture that target via closure
      setTimeout(() => {
        setForceUpdate(!forceUpdate);
      }, delay);
    };
    if (visible) {
      documentElement.addEventListener(refreshEventName, forceUpdateListener, true);
    }

    return () => {
      documentElement.removeEventListener(refreshEventName, forceUpdateListener, true);
    };
  }, [documentElement, forceUpdate, visible]);

  // Reset when un-mounting in hook above did not work on FF, so resetting also on path change
  const { pathname } = useLocation();
  useEffect(() => {
    hideAndClearTarget();
    setFocusedTarget(null);
  }, [pathname, hideAndClearTarget]);

  return (
    <div
      id={flyingFocusId}
      className={classnames(className, { fly: visible })}
      ref={ref}
      style={{
        ...positionOf(focusedTarget),
        zIndex: +getZIndex(focusedTarget) + 1000,
      }}
    />
  );
};

export const DisableOutlineGlobalStyle = createGlobalStyle`
  html {
    .${focusTargetClassName} {
      outline: none !important; /* Doesn't work in Firefox :( */
      /* http://stackoverflow.com/questions/71074/how-to-remove-firefoxs-dotted-outline-on-buttons-as-well-as-links/199319 */
      &::-moz-focus-inner {
        border: 0 !important;
      }
    }
  }
`;

export const StyledFlyingFocus = styled(FlyingFocus)`
  margin: 0;
  background: transparent;
  transition-property: left, top, width, height;
  transition-duration: ${duration}ms;
  pointer-events: none;
  visibility: hidden;

  &.${flyClassName} {
    visibility: visible;
  }

  /* Replace it with @supports rule when browsers catch up */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    border-radius: 8px;
    outline: 1px solid ${colors.DW_DARK_BLUE_NEW};
    box-shadow: 0px 0px 0px 4px ${colors.ACCENT_GREEN};
  }
`;
