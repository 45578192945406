import { useEffect } from 'react';

/**
 * Call _effect_ when ref loses focus, unless the focus transfers to a child of ref.
 *
 * @param {React.MutableRefObject<HTMLElement | undefined | null>} ref
 * @param {() => void} effect
 */
export function useOnFocusout(ref, effect) {
  useEffect(() => {
    let timeout;

    function focusout() {
      // Wait for a focusin event before triggering _effect_.
      timeout = setTimeout(effect, 0);
    }

    function focusin() {
      // focusout immidately followed by focusin means, that focus has transfered to a child.
      clearTimeout(timeout);
    }

    const navElement = ref.current;
    if (!navElement) {
      return undefined;
    }

    navElement.addEventListener('focusout', focusout);
    navElement.addEventListener('focusin', focusin);

    return () => {
      navElement.removeEventListener('focusout', focusout);
      navElement.removeEventListener('focusin', focusin);
      clearTimeout(timeout);
    };
  }, [effect, ref]);
}
