import { isServer } from './ssr';

const globals = {
  window: isServer() ? global.window || {} : window,
  document: isServer() ? {} : document,
  navigator: isServer() ? {} : navigator,
  localStorage: isServer() ? {} : window.localStorage,
  Image: isServer() ? function Image() {} : Image,
};

export default globals;
