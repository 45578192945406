export const description = /** @type {const} */ ({
  UNDETERMINED: 'UNDETERMINED',
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  LOGGING_IN: 'LOGGING_IN',
  LOGGING_OUT: 'LOGGING_OUT',
  UPDATING_USER: 'UPDATING_USER',
  DELETING_USER: 'DELETING_USER',
  CONFIRM_USER_EMAIL_CHANGE: 'CONFIRM_USER_EMAIL_CHANGE',
});

/** @typedef {typeof description} DescEnum */
/** @typedef {DescEnum[keyof DescEnum]} Description */

// IMPORTANT: Consider updating storage merger function if this changes
export const initialState = {
  userData: null,
  stateDescription: description.UNDETERMINED,
};
