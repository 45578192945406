/* eslint-disable no-undef, react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTimeout } from '../../hooks/useTimeout';
import globals from '../../utils/globals';
import { useScripts } from '../../hooks/useScripts';
import { useNavigationContext } from './NavigationContainer/NavigationContext.jsx';

// located in public folder
// @VisibleForTesting
export const tongwenScripts = [
  '/tongwen/tongwen_core.min.js',
  '/tongwen/tongwen_table_s2t.min.js',
  '/tongwen/tongwen_table_t2s.min.js',
  '/tongwen/tongwen_table_ps2t.min.js',
  '/tongwen/tongwen_table_pt2s.min.js',
];

export const URL_GET_PARAM_NAME = 'zhongwen';
export const URL_GET_PARAM_SIMPLIFIED = 'simp';
export const URL_GET_PARAM_TRADITIONAL = 'trad';

const TOGGLE_BUTTON_SIMPLE_TEXT_CONTENT = '简';
const TOGGLE_BUTTON_TRADITIONAL_TEXT_CONTENT = '繁';

export const useChineseSimpleTraditionalSwitch = toggleButtonId => {
  const [isScriptsLoaded, setScriptsLoaded] = useState(false);
  const [isTranslatedFirstTime, setTranslatedFirstTime] = useState(false);
  const setLoaded = useCallback(() => setScriptsLoaded(true), []);
  useScripts(tongwenScripts, setLoaded);

  const { isTraditionalChin, setIsTraditionalChin } = useNavigationContext();
  const { search } = useLocation();

  const setToggleButtonTextContent = content => {
    globals.document.getElementById(toggleButtonId).textContent = content;
  };

  // used to consider url request param if available
  useEffect(() => {
    if (search) {
      const cleanedSearch = search.replace('?', '');
      const urlParams = new URLSearchParams(cleanedSearch);
      const chineseVersionRequestParam = urlParams.get(URL_GET_PARAM_NAME);
      if (chineseVersionRequestParam === URL_GET_PARAM_SIMPLIFIED) {
        setIsTraditionalChin(false);
      } else if (chineseVersionRequestParam === URL_GET_PARAM_TRADITIONAL) {
        setIsTraditionalChin(true);
      }
    }
  }, []);

  // translate content on first load related to current local storage value
  useTimeout({
    callback: () => {
      if (isScriptsLoaded) {
        isTraditionalChin
          ? TongWen.trans2Trad(globals.document)
          : TongWen.trans2Simp(globals.document);
        setTranslatedFirstTime(true);
      }
    },
    delay: 300,
  });

  // if translated initially or later with toggling button - we need to set button content again
  // because the simple one is also translated into the traditional one.
  useTimeout(
    {
      callback: () => {
        if (isTraditionalChin) {
          setToggleButtonTextContent(TOGGLE_BUTTON_SIMPLE_TEXT_CONTENT);
        } else {
          setToggleButtonTextContent(TOGGLE_BUTTON_TRADITIONAL_TEXT_CONTENT);
        }
      },
      delay: 300,
    },
    [isTraditionalChin, isTranslatedFirstTime],
  );

  const trans2Simple = () => {
    if (isScriptsLoaded) {
      TongWen.trans2Simp(globals.document);
      setIsTraditionalChin(false);
    }
  };

  const trans2Traditional = () => {
    if (isScriptsLoaded) {
      TongWen.trans2Trad(globals.document);
      setIsTraditionalChin(true);
    }
  };

  return { isTraditionalChin, trans2Simple, trans2Traditional };
};
