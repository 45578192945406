import { accessPath } from './jsonPath';

export const replaceInPattern = (pattern, options) => {
  if (!options) {
    return pattern;
  }

  return Object.keys(options).reduce((acc, nextVar) => {
    const varNameRegex = new RegExp(/\${/.source + nextVar + /}/.source, 'g'); // ${nextVar}
    return acc.replace(varNameRegex, options[nextVar]);
  }, pattern);
};

const retrieveLabel = (key, translations) => {
  const translation = accessPath(key, translations);
  if (!translation) {
    return key;
  }
  return translation;
};

export function t(translations, key, options) {
  const label = retrieveLabel(key, translations);
  return replaceInPattern(label, options);
}
