export const formatRegistrationUserData = ({ userData, captchaToken, langCode }) => {
  const { sex, firstName, lastName, email, password, country, teacher, newsletter, year } =
    userData;

  return {
    sex,
    firstName,
    lastName,
    email,
    password,
    country,
    'g-recaptcha-response': captchaToken,
    teacher,
    newsletter,
    recaptcha: captchaToken,
    contactLanguage: langCode,
    ...(year && createDateOfBirth({ year })),
  };
};

export const formatUserProfileData = ({ data }) => {
  const { year, ...restProps } = data;

  return {
    ...restProps,
    ...(year && createDateOfBirth({ year })),
  };
};

const createDateOfBirth = ({ year }) => ({
  dateOfBirth: `${year}-01-01`,
});

export const deconstructDateOfBirth = dateOfBirth => {
  if (!dateOfBirth) return {};

  const datePartials = dateOfBirth.split('-');

  return {
    year: datePartials[0],
    month: datePartials[1],
    day: datePartials[2],
  };
};
