import { USER } from '../constants/userUrl';
import { BASIC_JSON_CONTENT_TYPE_HEADER } from '../constants/services';
import { formatRegistrationUserData, formatUserProfileData } from '../utils/userUtils';

export const LOGOUT_SUFFIX = 'public/logout';
const NOT_LOGGED_USER_SUFFIX = 'public/user';
const LOGGED_USER_SUFFIX = 'protected/user';
export const PROFILE_SUFFIX = 'profile';
const REGISTRATION_SUFFIX = 'registration';
export const PS_PREFIX = USER.PS_URL_WITH_DOMAIN;
const NOT_LOGGED_USER_RESOURCE = `${PS_PREFIX}${NOT_LOGGED_USER_SUFFIX}`;
export const LOGGED_USER_RESOURCE = `${PS_PREFIX}${LOGGED_USER_SUFFIX}`;
export const USER_CONFIRMATION_RESOURCE = `${PS_PREFIX}public/registration/confirmation`;
export const EMAIL_CONFIRMATION_RESOURCE = `${PS_PREFIX}public/mail/confirmation`;

export const registerUser = ({ userData, captchaToken, langCode }) => {
  const formattedData = formatRegistrationUserData({ userData, captchaToken, langCode });
  return fetch(`${NOT_LOGGED_USER_RESOURCE}/${REGISTRATION_SUFFIX}`, {
    method: 'POST',
    body: JSON.stringify(formattedData),
    dataType: 'json',
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  });
};

export const requestLogin = () => {
  return fetch(`${LOGGED_USER_RESOURCE}/${PROFILE_SUFFIX}`, BASIC_JSON_CONTENT_TYPE_HEADER).then(
    response => {
      return response.headers.get('content-type')?.includes('application/json')
        ? response.json()
        : {};
    },
  );
};

export const requestLogout = () => {
  return fetch(`${PS_PREFIX}${LOGOUT_SUFFIX}`).then(response =>
    response.ok ? Promise.resolve(response) : Promise.reject(response),
  );
};

export const deleteUser = () =>
  fetch(LOGGED_USER_RESOURCE, {
    method: 'DELETE',
  }).then(response => (response.ok ? Promise.resolve(response) : Promise.reject(response)));

export const checkUserProfile = () =>
  fetch(`${LOGGED_USER_RESOURCE}/${PROFILE_SUFFIX}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  }).catch(response => {
    console.error(`Unable to retrieve user:${JSON.stringify(response)}`);
  });

export const getUserProfile = () =>
  fetch(`${LOGGED_USER_RESOURCE}/${PROFILE_SUFFIX}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  })
    .then(response => response.json())
    .catch(response => {
      console.error(`Unable to retrieve user:${JSON.stringify(response)}`);
    });

export const updateUserProfile = data => {
  const userData = formatUserProfileData({ data });
  return fetch(`${LOGGED_USER_RESOURCE}/${PROFILE_SUFFIX}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
    method: 'POST',
    body: JSON.stringify(userData),
  }).then(response => (response.ok ? response.json() : Promise.reject(response)));
};

export const getUserByToken = token => fetch(`${NOT_LOGGED_USER_RESOURCE}/mail?token=${token}`);

export const confirmUserRegistration = confirmationId => {
  return fetch(`${USER_CONFIRMATION_RESOURCE}?confirmationId=${confirmationId}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
    method: 'POST',
  });
};

export const confirmEmailChange = confirmationId => {
  return fetch(`${EMAIL_CONFIRMATION_RESOURCE}?confirmationId=${confirmationId}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
    method: 'POST',
  }).then(response => (response.ok ? response.json() : Promise.reject(response)));
};

export const login = options =>
  fetch(`${USER.PS_URL}login`, {
    method: 'post',
    headers: { accept: 'application/json', 'content-type': 'application/json' },
    ...options,
  });
