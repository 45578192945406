export const keyCodes = Object.freeze({
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  NUMPAD_ENTER: 'NumpadEnter',
  SPACEBAR: ' ',
  TAB: 'Tab',
});
