import styled from 'styled-components';
import { DetailIntroText } from '../Text/Text.jsx';
import { colors } from '../../utils/css';

// @used in ROAD
const ErrorMessage = ({ className, error = 'No Data Available' }) => (
  <div className={className}>
    <DetailIntroText>{error}</DetailIntroText>
  </div>
);
export const StyledErrorMessage = styled(ErrorMessage)`
  > ${DetailIntroText} {
    color: ${colors.BREAKING_RED};
  }
`;
