import gql from 'graphql-tag';
import { LESSON_PARTS } from '../constants/lessonParts';
import { groupBy, isFinalTestPredicate } from './commons';
import { getAlphabeticalOrderUrl } from './url/urlFactory';
import { memoizeConst } from './memoize';

const overviewSortingOrder = Object.freeze({
  EXERCISE: 1,
  PHONEMIC_CHART: 2,
  GRAMMAR: 3,
  VOCABULARY_GLOSSARY: 4,
  REGIONAL_STUDIES: 5,
  MANUSCRIPT: 6,
  DOWNLOAD: 7,
});

export const sortOverviewItems = (item1, item2) =>
  overviewSortingOrder[item1.lessonPart] - overviewSortingOrder[item2.lessonPart];

export const addPhonemicChartOverviewPart = (lessonName, overviewItems) => {
  const phonemicChart = {
    lessonPart: LESSON_PARTS.PHONEMIC_CHART,
    target: { name: lessonName },
  };
  return [...overviewItems, phonemicChart].sort(sortOverviewItems);
};

export const hasLessonPart = (lessonOverviewParts, lessonPart) => {
  return lessonOverviewParts.some(part => part.lessonPart === lessonPart);
};

export const getFirstOfTypeNamedUrl = (lessonOverviewParts, lessonPart) =>
  (hasLessonPart(lessonOverviewParts, lessonPart) &&
    lessonOverviewParts.find(part => part.lessonPart === lessonPart).target.namedUrl) ||
  null;

export const filterLessonKnowledgeOverviewItems = lessonOverviewParts =>
  lessonOverviewParts.filter(part =>
    [
      LESSON_PARTS.GRAMMAR,
      LESSON_PARTS.VOCABULARY_GLOSSARY,
      LESSON_PARTS.REGIONAL_STUDIES,
      LESSON_PARTS.PHONEMIC_CHART,
    ].includes(part.lessonPart),
  );

export const filterLessonExerciseOverviewItems = lessonOverviewParts =>
  lessonOverviewParts.filter(part => part.lessonPart === LESSON_PARTS.EXERCISE);

export const isFinalTestLesson = ({ lessonContentLinksFromCourse = [], currentLessonId }) => {
  const finalTestLessonContentLink = lessonContentLinksFromCourse.find(
    contentLink => contentLink.targetId === +currentLessonId && isFinalTestPredicate(contentLink),
  );
  return Boolean(finalTestLessonContentLink);
};

export const filteredAssembledInfos = ({ lessonOverviewParts, lessonPath }) => {
  return assembleOverviewInfos({ lessonOverviewParts, lessonPath }).filter(info => info.hasInfo);
};

export const assembleOverviewInfos = ({ lessonOverviewParts, lessonPath }) => {
  const firstGrammarUrl = getFirstOfTypeNamedUrl(lessonOverviewParts, LESSON_PARTS.GRAMMAR);
  const firstRegionalUrl = getFirstOfTypeNamedUrl(
    lessonOverviewParts,
    LESSON_PARTS.REGIONAL_STUDIES,
  );
  const vocabularyUrl = getFirstOfTypeNamedUrl(
    lessonOverviewParts,
    LESSON_PARTS.VOCABULARY_GLOSSARY,
  );

  return [
    {
      name: 'grammar',
      hasInfo: hasLessonPart(lessonOverviewParts, LESSON_PARTS.GRAMMAR),
      url: firstGrammarUrl,
    },
    {
      name: 'alphabeticalOrder',
      hasInfo: hasLessonPart(lessonOverviewParts, LESSON_PARTS.PHONEMIC_CHART),
      url: getAlphabeticalOrderUrl(lessonPath),
    },
    {
      name: 'vocabulary',
      hasInfo: hasLessonPart(lessonOverviewParts, LESSON_PARTS.VOCABULARY_GLOSSARY),
      url: vocabularyUrl,
    },
    {
      name: 'regionalStudies',
      hasInfo: hasLessonPart(lessonOverviewParts, LESSON_PARTS.REGIONAL_STUDIES),
      url: firstRegionalUrl,
    },
  ];
};

export const getLessonStructuralInfo = ({ contentLinks, lessonId }) => {
  if (!contentLinks || contentLinks.length === 0) {
    return {};
  }
  const groupedLessons = groupBy('groupName', contentLinks);
  const currentLessonGroup = contentLinks.find(
    contentLink => contentLink.targetId === lessonId,
  )?.groupName;
  const lessonGroupIndex = Object.keys(groupedLessons).indexOf(currentLessonGroup);
  const lessonGroupNumber = lessonGroupIndex >= 0 ? lessonGroupIndex + 1 : undefined;
  const lessonIndexInGroup = groupedLessons[currentLessonGroup]
    .map(contentLink => contentLink.targetId)
    .indexOf(lessonId);
  const lessonNumberInLessonGroup = lessonIndexInGroup >= 0 ? lessonIndexInGroup + 1 : undefined;
  const lessonIndex = contentLinks.map(cl => cl.targetId).indexOf(lessonId);
  const lessonNumber = lessonIndex >= 0 ? lessonIndex + 1 : undefined;
  return { lessonGroupNumber, lessonNumberInLessonGroup, lessonNumber };
};

/** Minimal fragment to call hasLessonExtras() */
const fragmentName = 'HasLessonExtras';
export const hasLessonExtrasFragment = {
  name: fragmentName,
  fragment: memoizeConst(
    () => gql`
      fragment ${fragmentName} on Lesson {
        contentLinks(targetTypes: [ARTICLE, DOWNLOAD, AUDIO, VIDEO]) {
          targetType
        }
        externalLinks {
          modelType
        }
      }
    `,
  ),
};

/**
 * @param {{ externalLinks?: { modelType?: unknown }[] | null }} lesson
 */
function hasExternalLink(lesson) {
  return lesson.externalLinks?.some(l => l?.modelType === 'EXTERNAL_LINK') ?? false;
}

/**
 * @param {{ contentLinks?: { targetType?: unknown }[] | null }} lesson
 */
function hasExtraContentLink(lesson) {
  return (
    lesson.contentLinks?.some(cl =>
      ['ARTICLE', 'DOWNLOAD', 'AUDIO', 'VIDEO'].includes(cl?.targetType),
    ) ?? false
  );
}

/**
 * @param {Parameters<typeof hasExternalLink>[0] & Parameters<typeof hasExtraContentLink>[0]} lesson
 */
export function hasLessonExtras(lesson) {
  return hasExternalLink(lesson) || hasExtraContentLink(lesson);
}
