import { MARKS } from '../constants/marks';
import { useLanguage } from '../context/LanguageContext';
import { useNavigationRoot } from '../context/NavigationRootContext';
import { getDashboardUrl } from './url/urlFactory';

// converts dkLearningLevel value to string learning levels
export const learningLevelMapper = /** @type {const} */ ({
  0: '',
  2: 'A1',
  4: 'A2',
  8: 'B1',
  16: 'B2',
  32: 'C1',
  64: 'C2',
});

/** @typedef {Exclude<keyof typeof learningLevelMapper, 0>} LearningLevelFlag */
/** @typedef {typeof learningLevelMapper[LearningLevelFlag]} LearningLevel */

/**
 * @param {number} dkLearningLevel
 * @returns {LearningLevel[]}
 */
export function getLearningLevels(dkLearningLevel) {
  return (
    Object.entries(learningLevelMapper)
      .map(([flag, level]) => [parseInt(flag, 10), level])
      // Note that level 0 will always be filtered out
      .filter(([flag]) => (dkLearningLevel & flag) > 0) // eslint-disable-line no-bitwise
      // Ensure ascending order
      .sort(([flagA], [flagB]) => flagA - flagB)
      .map(([, level]) => level)
  );
}

/**
 * @param {LearningLevel} currentLevel
 * @param {number} resultPercent
 * @returns {LearningLevel}
 */
export const nextRecommendedLevel = (currentLevel, resultPercent) => {
  if (resultPercent <= MARKS.MEDIUM) return currentLevel;

  const levelValues = Object.values(learningLevelMapper);
  const currentIndex = levelValues.indexOf(currentLevel);

  return levelValues[currentIndex + 1] || currentLevel;
};

export const calculateCourseNote = resultPercent => {
  if (resultPercent >= MARKS.GOOD) return 'veryGood';
  if (resultPercent >= MARKS.MEDIUM) return 'good';
  if (resultPercent >= MARKS.BAD) return 'bad';
  return 'veryBad';
};

/**
* @param {LearningLevel} learningLevel
@returns {string | null}
*/
export const useLevelUrl = learningLevel => {
  const learningLevelNavigations = useNavigationRoot()?.learningLevelNavigations;
  const { langCode } = useLanguage();

  if (!Array.isArray(learningLevelNavigations)) {
    return getDashboardUrl(langCode);
  }

  // NOTE: may need to add anchor later
  switch (learningLevel) {
    case 'A1':
    case 'A2':
      return learningLevelNavigations[0]?.namedUrl ?? null;
    case 'B1':
    case 'B2':
      return learningLevelNavigations[1]?.namedUrl ?? null;
    case 'C1':
    case 'C2':
      return learningLevelNavigations[2]?.namedUrl ?? null;
    default:
      return null;
  }
};
