import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { removeZhongwenUrlSearchParamFromPath } from '../../utils/url/url';

export const Link = ({ className, native = false, children, to, ...restProps }) => {
  const Tag = native ? 'a' : RouterLink;
  const linkTarget = removeZhongwenUrlSearchParamFromPath(to);
  const linkProperty = native ? { href: linkTarget } : { to: linkTarget };

  return (
    <Tag className={className} {...linkProperty} {...restProps}>
      {children}
    </Tag>
  );
};

export const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  &:not(.link-in-teaser) {
    font-weight: bold;
    * {
      font-weight: bold;
    }
  }
  &:hover {
    text-decoration: underline;
  }
  &.link-no-link:hover {
    text-decoration: none;
  }
  svg {
    fill: currentColor;
  }

  &:focus {
    outline: none;
  }
`;
