// watch out - arrays not supported ;) Maybe in future
// we should replace this small function with something more powerful
// but let us wait till it is really needed.

function accessPathByArr(keysArr, obj) {
  if (!obj) {
    return null;
  }
  const objPartial = obj[keysArr[0]];

  if (!objPartial) {
    return null;
  }
  if (keysArr.length === 1) {
    return objPartial;
  }
  return accessPathByArr(keysArr.slice(1), objPartial);
}

export function accessPath(pathStr, obj) {
  if (!pathStr) {
    return obj;
  }
  return accessPathByArr(pathStr.split('.'), obj);
}
