import styled from 'styled-components';
import { colors, mediaMin, resolutions } from '../../utils/css';

const PageLayout = ({ className, children }) => (
  <div className={className} aria-label="Page Container">
    <div className="content-container">{children}</div>
  </div>
);

export const StyledPageLayout = styled(PageLayout)`
  background: ${colors.LG_WHITE};
  display: grid;
  margin: 60px auto 0;
  overflow-x: hidden !important;
  padding: 0;

  ${mediaMin.md`
    margin: 90px auto 40px;
  `}

  ${mediaMin.xl`
    min-height: 600px;
    padding: 0;
    width: ${resolutions.min.xl}px;

    .content-container {
      width: ${resolutions.min.xl}px;
    }
  `}
`;
