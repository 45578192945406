import { createHttpLink, ApolloLink, from } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import config from '../config';

export const extendVariablesWithAppNameLink = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line no-param-reassign
  operation.variables.appName = operation.variables.appName || 'mdl';
  return forward(operation);
});
export const persistedQueryLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
  sha256,
});
// @VisibleForTesting
export const httpLink = () =>
  createHttpLink({ uri: config.graphQL.baseUrl, includeUnusedVariables: true });

export const makeClientSideApolloLink = () => ({
  link: from([extendVariablesWithAppNameLink, persistedQueryLink, httpLink()]),
});
