import styled from 'styled-components';
import classnames from 'classnames';
import { colors, mediaMax } from '../../utils/css';
import { useTranslation } from '../../hooks/useTranslation';
import { ButtonInteraction } from '../Interaction/Interaction.jsx';

const StandardButton = ({
  titleKey,
  isA = 'button',
  children,
  variant = 'primary',
  className,
  selected,
  ...restProps
}) => {
  const label = useTranslation(titleKey);

  return (
    <ButtonInteraction
      as={isA}
      className={classnames(className, variant, {
        btnSelected: selected,
      })}
      {...restProps}
    >
      {titleKey ? label : children}
    </ButtonInteraction>
  );
};

export const StyledStandardButton = styled(StandardButton)`
  text-decoration: none;
  /* both vertically and horizontally 2px must be subtracted for the border */
  padding: calc(10px - var(--border-width)) calc(1rem - var(--border-width));
  background-color: var(--bg-color);
  outline: none;
  cursor: pointer;
  border-radius: 5px;

  /* transparency makes sure the content doesn't readjust on hover */
  --border-color: transparent;
  --border-width: 2px;
  border: solid var(--border-width) var(--border-color);

  ${mediaMax.xs`
    width: 100%;
  `}

  :disabled {
    cursor: default;
    pointer-events: none;
  }

  &.primary {
    --bg-color: ${colors.DW_YELLOW};
  }

  &.secondary {
    --bg-color: ${colors.BLUE_GREY_02};
  }

  /* NOTE: using :not(:disabled) instead of :enabled because the latter doesn't work on links */
  &.tertiary:not(:disabled) {
    --bg-color: transparent;

    :not(:hover) {
      --border-color: ${colors.BLUE_GREY_03};
    }
  }

  &.quaternary {
    --bg-color: transparent;
  }

  :hover:not(:disabled) {
    :not(.quaternary) {
      --border-color: ${colors.DW_DARK_BLUE_NEW};
    }
  }

  :active:not(:disabled),
  &.btnSelected {
    :not(.quaternary),
    :not(.quaternary):hover {
      --bg-color: ${colors.DW_DARK_BLUE_NEW};
      color: ${colors.LG_WHITE};
    }

    &.quaternary {
      text-decoration: underline;
    }
  }

  :disabled:not(.btnSelected) {
    color: ${colors.BLUE_GREY_03};

    :not(.quaternary) {
      --bg-color: ${colors.BLUE_GREY_02};
    }
  }
`;
