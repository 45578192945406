export const commonMenuStyles = `
  --transition-speed: 0.3s;

  transform: translateY(-100%);
  visibility: hidden;
  transition: transform var(--transition-speed) ease-in-out,
    visibility 0s linear var(--transition-speed);

  &.open {
    transform: translateY(0);
    visibility: visible;
    transition: transform var(--transition-speed) ease-in-out, visibility 0s linear;
  }
`;
