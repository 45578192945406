import { html2text, PAGE_TYPE_TO_DESCRIPTION_TRANSLATION_KEYS } from '../utils/pageMetadata';
import { useTranslation } from './useTranslation';

export const useMetaDescription = ({
  pageType,
  contentName,
  teaser,
  description,
  useTeaserAsDescription,
  useDescriptionTranslation,
} = {}) => {
  const descriptionTranslation = useTranslation({
    key: PAGE_TYPE_TO_DESCRIPTION_TRANSLATION_KEYS[pageType],
    parameters: { first: contentName },
  });

  return (
    (useTeaserAsDescription && html2text({ html: teaser })) ||
    (useDescriptionTranslation && descriptionTranslation) ||
    html2text({ html: description })
  );
};
