import { useEffect, useRef } from 'react';
import { isServer } from '../utils/ssr';
import globals from '../utils/globals';

export const useTimeout = ({ callback, delay = 100, conditional = true }, triggers = []) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (isServer() || !conditional) {
      return undefined;
    }
    const handleId = globals.window.setTimeout(tick, delay);
    return () => globals.window.clearTimeout(handleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, conditional, ...triggers]);
};
