import styled, { keyframes } from 'styled-components';
import { Spinner } from '../Spinner.jsx';

// @used in ROAD
export const LoadingMessage = ({ className }) => (
  <div className={className}>
    <Spinner />
  </div>
);

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledLoadingMessage = styled(LoadingMessage)`
  display: flex;
  justify-content: center;
  height: 100vh;

  ${Spinner} {
    margin-top: 60px;
    opacity: 0;
    animation: ${fadeIn} 500ms cubic-bezier(0.43, 0, 0.09, 1) 1 forwards;
  }
`;
