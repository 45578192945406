import styled from 'styled-components';
import { colors, resolutions, columnSize, fontSize, mediaMin } from '../../utils/css';
import dwLogoMobile from '../../assets/svg/dw-logo-mobile.svg';
import dwLogo from '../../assets/svg/dw-logo.svg';
import { I18nText } from '../I18n/I18nText.jsx';
import { TeaserHeadline } from '../Headline/Headline.jsx';
import { LANG_CODES } from '../../utils/mappers/langMapper';
import { StyledChineseVariantSwitch } from './ChineseVariantSwitch.jsx';
import { StyledBurgerButton as BurgerButton } from './BurgerButton/BurgerButton.jsx';
import { useLanguage } from '../../context/LanguageContext';
import { getDashboardUrl } from '../../utils/url/urlFactory';

export const Banner = ({ className }) => {
  const { langCode } = useLanguage();
  const homeURL = getDashboardUrl(langCode);
  const headerTitleKey = 'header.bar';

  return (
    <div className={className}>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href={homeURL} data-testid="dwMobileLogo" />
        <I18nText isA={TeaserHeadline} as="div" translation={headerTitleKey} />
        <div>
          {langCode === LANG_CODES.CHINESE && <StyledChineseVariantSwitch />}
          <BurgerButton />
        </div>
      </div>
    </div>
  );
};

export const StyledBanner = styled(Banner)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1050;

  background-color: ${colors.LG_WHITE};
  border-width: 0 0 1px;
  box-shadow: inset 0 1px 0 ${colors.LG_TRANSPARENT_WHITE_15},
    0 1px 5px ${colors.LG_TRANSPARENT_BLACK_075};
  border-bottom: 1px solid ${colors.DW_GREY_02};

  > div {
    max-width: ${resolutions.min.xl}px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 0 ${columnSize.c1};
    height: 100%;

    > a {
      grid-column: 1;
      justify-self: start;
      display: flex;
      align-items: center;
      background: url(${dwLogoMobile});
      height: 28px;
      width: 49px;

      ${mediaMin.md`
        background: url(${dwLogo});
        height: 53px;
        width: 93px;
      `}
    }

    > ${TeaserHeadline} {
      grid-column: 2;
      ${fontSize.plus2}

      ${mediaMin.md`
        ${fontSize.plus3}
      `}
    }

    > div:nth-child(3) {
      grid-column: 3;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;
