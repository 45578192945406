import { LANG_CODES } from '../utils/mappers/langMapper';

const CHINESE_SIMP_POSTFIX = '_simp';
const CHINESE_TRAD_POSTFIX = '_trad';

export const CHINESE_SIMP_TRANSLATION_KEY = `${LANG_CODES.CHINESE}${CHINESE_SIMP_POSTFIX}`;
export const CHINESE_TRAD_TRANSLATION_KEY = `${LANG_CODES.CHINESE}${CHINESE_TRAD_POSTFIX}`;

export const MENU_LANGUAGES = [
  { translationKey: LANG_CODES.ARABIC, langCode: LANG_CODES.ARABIC },
  { translationKey: LANG_CODES.BOSNIAN, langCode: LANG_CODES.BOSNIAN },
  { translationKey: CHINESE_SIMP_TRANSLATION_KEY, langCode: LANG_CODES.CHINESE },
  { translationKey: CHINESE_TRAD_TRANSLATION_KEY, langCode: LANG_CODES.CHINESE },
  { translationKey: LANG_CODES.ENGLISH, langCode: LANG_CODES.ENGLISH },
  { translationKey: LANG_CODES.FRENCH, langCode: LANG_CODES.FRENCH },
  { translationKey: LANG_CODES.GERMAN, langCode: LANG_CODES.GERMAN },
  { translationKey: LANG_CODES.GREEK, langCode: LANG_CODES.GREEK },
  { translationKey: LANG_CODES.HUNGARIAN, langCode: LANG_CODES.HUNGARIAN },
  { translationKey: LANG_CODES.PERSIAN, langCode: LANG_CODES.PERSIAN },
  { translationKey: LANG_CODES.PORTUGUESE_BRAZIL, langCode: LANG_CODES.PORTUGUESE_BRAZIL },
  { translationKey: LANG_CODES.POLISH, langCode: LANG_CODES.POLISH },
  { translationKey: LANG_CODES.ROMANIAN, langCode: LANG_CODES.ROMANIAN },
  { translationKey: LANG_CODES.RUSSIAN, langCode: LANG_CODES.RUSSIAN },
  { translationKey: LANG_CODES.SPANISH, langCode: LANG_CODES.SPANISH },
  { translationKey: LANG_CODES.TURKISH, langCode: LANG_CODES.TURKISH },
  { translationKey: LANG_CODES.UKRANIAN, langCode: LANG_CODES.UKRANIAN },
];
