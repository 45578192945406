import { createGlobalStyle } from 'styled-components';
import { colors } from '../utils/css';
import { StyledPageLayout as PageLayout } from '../components/Page/PageLayout.jsx';
import { GlobalFonts } from '../components/GlobalFonts/GlobalFonts.jsx';
import { useCheckSessionTimeout } from '../hooks/useCheckSessionTimeout';
import { useSyncLearnProgress } from '../hooks/useSyncLessonProgress';
import { useScrollToTop } from '../hooks/useScrollToTop';
import {
  DisableOutlineGlobalStyle,
  StyledFlyingFocus,
} from '../components/FlyingFocus/FlyingFocus.jsx';
import { useLanguage } from '../context/LanguageContext';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: ${props => (props.isRtl ? '0!important' : 'normal')};
  }
  &:after, &:before {
    box-sizing: border-box;
  }
  html {
    font-size: min(calc(15px + ((100vw - 375px) / 213)), 20px);
    -webkit-tap-highlight-color: transparent;
    ${props => (props.langCode === 'ar' ? 'font-feature-settings: "liga" 0;' : '')}
  }
  body {
    direction: ltr;
    text-align: left;
    background-color: ${colors.LG_WHITE};
  }
  svg:not(:root) {
    overflow: hidden;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  button {
    cursor: pointer;
    &:disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  p, li {
    margin: 0 0 10px;
  }

  dl, ol, ul {
    margin-top: 0;
  }

  strong, b {
    font-weight: 600;
  }
`;

export const MainPage = ({ children }) => {
  const { langCode, isRtl } = useLanguage();
  useCheckSessionTimeout(langCode);
  useSyncLearnProgress();
  useScrollToTop();

  return (
    <>
      <GlobalStyles isRtl={isRtl} langCode={langCode} />
      <DisableOutlineGlobalStyle />
      <StyledFlyingFocus />
      <GlobalFonts />
      <PageLayout>{children}</PageLayout>
    </>
  );
};
