import { LOAD } from '../redux-storage';
import { initialState, description } from './userState';
import {
  confirmEmailChange,
  deleteUser,
  requestLogin,
  requestLogout,
  updateUserProfile,
} from '../../services/userService';
import { removeProgress } from '../progress/learnProgressDuck';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const CONFIRM_USER_EMAIL_CHANGE_START = 'CONFIRM_USER_EMAIL_CHANGE_START';
export const CONFIRM_USER_EMAIL_CHANGE_SUCCESS = 'CONFIRM_USER_EMAIL_CHANGE_SUCCESS';
export const CONFIRM_USER_EMAIL_CHANGE_ERROR = 'CONFIRM_USER_EMAIL_CHANGE_ERROR';

export const logInUser = () => dispatch => {
  dispatch(loginStart());
  return requestLogin()
    .then(data => {
      dispatch(loginSuccess(data));
    })
    .catch(err => {
      dispatch(loginError(err));
      return Promise.reject(err);
    });
};

export const logOutUser = () => dispatch => {
  dispatch(logoutStart());
  return requestLogout()
    .then(() => {
      dispatch(logoutSuccess());
    })
    .catch(err => {
      dispatch(logoutError(err));
      return Promise.reject(err);
    });
};

export const deleteUserAccount = () => dispatch => {
  dispatch(deleteStart());
  return deleteUser()
    .then(() => {
      dispatch(deleteSuccess());
      return dispatch(logOutUser());
    })
    .catch(err => {
      dispatch(deleteError(err));
      return Promise.reject(err);
    });
};

export const updateUser = data => dispatch => {
  dispatch(updateUserStart());
  return updateUserProfile(data)
    .then(updatedData => {
      dispatch(updateUserSuccess(updatedData));
      return updatedData;
    })
    .catch(err => {
      dispatch(updateUserError(err));
      return Promise.reject(err);
    });
};

export const confirmUserEmailChange = confirmationId => dispatch => {
  dispatch(confirmUserEmailChangeStart());
  return confirmEmailChange(confirmationId)
    .then(updatedData => {
      dispatch(confirmUserEmailChangeSuccess(updatedData));
      return updatedData;
    })
    .catch(err => {
      dispatch(confirmUserEmailChangeError(err));
      return Promise.reject(err);
    });
};

const updateUserStart = () => ({ type: UPDATE_USER_START });
const updateUserSuccess = data => ({ type: UPDATE_USER_SUCCESS, payload: data });
const updateUserError = err => ({ type: UPDATE_USER_ERROR, payload: err });

const loginStart = () => ({ type: LOGIN_START });
const loginSuccess = data => ({ type: LOGIN_SUCCESS, payload: data });
const loginError = () => ({ type: LOGIN_ERROR });
const logoutStart = () => ({ type: LOGOUT_START });
const logoutSuccess = () => dispatch => {
  dispatch({ type: LOGOUT_SUCCESS });
  dispatch(removeProgress());
};
const logoutError = () => ({ type: LOGOUT_ERROR });

const deleteStart = () => ({ type: DELETE_USER_START });
const deleteSuccess = () => ({ type: DELETE_USER_SUCCESS });
const deleteError = err => ({ type: DELETE_USER_ERROR, payload: err });

const confirmUserEmailChangeStart = () => ({ type: CONFIRM_USER_EMAIL_CHANGE_START });
const confirmUserEmailChangeSuccess = data => ({
  type: CONFIRM_USER_EMAIL_CHANGE_SUCCESS,
  payload: data,
});
const confirmUserEmailChangeError = err => ({
  type: CONFIRM_USER_EMAIL_CHANGE_ERROR,
  payload: err,
});

export const loginTimeout = () => ({
  type: SESSION_TIMEOUT,
});

/**
 * @returns {typeof initialState}
 */
export const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        stateDescription:
          state.stateDescription === description.UNDETERMINED || !state.stateDescription
            ? description.LOGGED_OUT
            : state.stateDescription,
      };
    case UPDATE_USER_START:
      return {
        ...state,
        stateDescription: description.UPDATING_USER,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        stateDescription: description.LOGGED_IN,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        stateDescription: description.LOGGED_IN,
      };
    case CONFIRM_USER_EMAIL_CHANGE_START:
      return {
        ...state,
        stateDescription: description.CONFIRM_USER_EMAIL_CHANGE,
      };
    case CONFIRM_USER_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        userData: state.userData ? action.payload : null,
        stateDescription: state.userData ? description.LOGGED_IN : description.LOGGED_OUT,
      };
    case CONFIRM_USER_EMAIL_CHANGE_ERROR:
      return {
        ...state,
        stateDescription: state.userData ? description.LOGGED_IN : description.LOGGED_OUT,
      };
    case LOGIN_START:
      return {
        ...state,
        stateDescription: description.LOGGING_IN,
      };
    case LOGOUT_START:
      return {
        ...state,
        stateDescription: description.LOGGING_OUT,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        stateDescription: description.LOGGED_IN,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        userData: null,
        stateDescription: description.LOGGED_OUT,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        stateDescription: description.LOGGED_OUT,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        stateDescription: description.LOGGED_IN,
      };
    case DELETE_USER_START:
      return {
        ...state,
        stateDescription: description.DELETING_USER,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        stateDescription: description.LOGGING_OUT,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        stateDescription: description.LOGGED_IN,
      };
    case SESSION_TIMEOUT:
      return {
        ...initialState,
        stateDescription: description.LOGGED_OUT,
      };
    default:
      return state;
  }
};
