import { hydrateRoot, createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { App } from './components/App.jsx';
import { cacheOptions } from './utils/apolloClient';
import store, { StoreLoader } from './state/store';
import { makeClientSideApolloLink } from './utils/apolloLink';

const cache = new InMemoryCache(cacheOptions);

const client = new ApolloClient({
  link: makeClientSideApolloLink().link,
  ssrForceFetchDelay: 100,
  cache: cache.restore(window.__APOLLO_STATE__), // eslint-disable-line no-underscore-dangle
});

const container = document.getElementById('root');

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <StoreLoader>
        <Router>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Router>
      </StoreLoader>
    </Provider>
  </ApolloProvider>
);

if (import.meta.env.PROD) {
  hydrateRoot(container, app);
} else {
  createRoot(container).render(app);
}

serviceWorker.unregister();
