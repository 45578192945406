import { FEEDBACK_TYPES } from '../../constants/feedback';

export const pathPartials = {
  // IMPORTANT: Avoid groups in regex, always check here: https://pshrmn.github.io/route-tester/
  langCodeRegex: '[a-z]{2}-?0?0?2?[briaf]{0,2}',
  titlePathRegex: '.*',
  contentIdRegex: '\\d+',
  knowledgeTypeRegex: 'lv|la',
  knowledgeWithContentIdTypeRegex: 'gr|rs',
  feedbackTypes: Object.values(FEEDBACK_TYPES).join('|'),
};
export const isPathMatching = ({ namedUrl, pathname }) =>
  namedUrl.startsWith(pathname) || namedUrl.startsWith(`/${pathname}`);
