import { objectToQueryParams } from '../../utils/url/url';
import { useFrontendConfig } from '../../context/FrontendConfigContext';

// @used in ROAD
export const GtmNoScriptFallback = ({ dataLayer }) => {
  const hideIframeStyles = {
    display: 'none',
    visibility: 'hidden',
  };
  const { gtmId } = useFrontendConfig();
  return (
    <noscript>
      <iframe
        title={`GTM-${gtmId}`}
        src={`https://www.googletagmanager.com/ns.html?id=GTM-${gtmId}&${objectToQueryParams(
          dataLayer,
        )}`}
        height="0"
        width="0"
        style={hideIframeStyles}
      />
    </noscript>
  );
};
