import {
  addCourseProgressData,
  deleteCourseProgressById,
  deleteLessonProgressById,
  deleteLessonProgressesByCourseId,
  findExistingLesson,
  generateLessonProgress,
  initializeLessonLearnProgressWithDataFromServer,
  updateLessonProgressFromService,
} from './learnProgress';
import {
  COURSE_TYPE,
  deleteLearnProgress,
  getProgress,
  getProgresses,
  initCourseProgress,
  LESSON_TYPE,
  saveLessonProgress,
  saveLessonProgresses,
} from '../../services/learnProgressService';
import { userIsLoggedIn } from '../user/userSelectors';

export const ADD_LESSON_PROGRESS = 'ADD_LESSON_PROGRESS';
export const READ_LESSON_PROGRESS = 'READ_LESSON_PROGRESS';
export const READ_LESSON_PROGRESSES = 'READ_LESSON_PROGRESSES';
export const DELETE_LESSON_PROGRESS = 'DELETE_LESSON_PROGRESS';
export const READ_COURSE_PROGRESS = 'ADD_COURSE_PROGRESS';
export const DELETE_COURSE_PROGRESS = 'DELETE_COURSE_PROGRESS';
export const DELETE_LESSON_PROGRESSES = 'DELETE_LESSON_PROGRESSES';
export const REMOVE_LEARN_PROGRESS = 'REMOVE_LEARN_PROGRESS';
export const LESSON_PROGRESS_SYNC_NEEDED = 'LESSON_PROGRESS_SYNC_NEEDED';

// external used actions
export const addLessonProgress = lessonProgress => {
  return dispatch => {
    dispatch(addProgress(lessonProgress));
    dispatch(saveProgressOnServer(lessonProgress));
  };
};

export const deleteLessonProgress = lessonId => {
  return dispatch => {
    dispatch(deleteLProgress(lessonId));
    dispatch(deleteProgressOnServer(lessonId, LESSON_TYPE));
  };
};

export const readLessonProgress = lessonId => {
  return (dispatch, getState) => {
    if (userIsLoggedIn(getState())) {
      getProgress(lessonId, LESSON_TYPE).then(progress => dispatch(readProgress(progress)));
    }
  };
};

export const readLessonProgresses = lessonIds => {
  return (dispatch, getState) => {
    if (userIsLoggedIn(getState())) {
      getProgresses(lessonIds, LESSON_TYPE).then(progresses =>
        dispatch(readProgresses(progresses)),
      );
    }
  };
};

export const readCourseProgress = courseId => {
  return (dispatch, getState) => {
    if (userIsLoggedIn(getState())) {
      getProgress(courseId, COURSE_TYPE).then(courseProgress => {
        if (courseProgress) {
          dispatch(addCourseProgress(courseProgress));
        }
      });
    }
  };
};

export const initCourse = initialCourseData => {
  return (dispatch, getState) => {
    if (userIsLoggedIn(getState())) {
      initCourseProgress(initialCourseData).then(courseProgress =>
        dispatch(readCourseProgress(courseProgress.cosCourseId)),
      );
    }
  };
};

export const deleteCourseProgress = courseId => {
  return dispatch => {
    dispatch(deleteCProgress(+courseId));
    dispatch(deleteProgressOnServer(courseId, COURSE_TYPE));
    dispatch(deleteLessonProgresses(+courseId));
  };
};

export const removeProgress = () => ({
  type: REMOVE_LEARN_PROGRESS,
});

export const setLessonProgressSyncNeeded = isSyncNeeded => ({
  type: LESSON_PROGRESS_SYNC_NEEDED,
  payload: isSyncNeeded,
});

export const syncLessonProgress = () => {
  return (dispatch, getState) => {
    const { learnProgress } = getState();
    const { lessons } = learnProgress;
    return saveLessonProgresses(lessons).then(() => dispatch(setLessonProgressSyncNeeded(false)));
  };
};

// internal actions

const addCourseProgress = courseProgress => ({
  type: READ_COURSE_PROGRESS,
  payload: courseProgress,
});

const addProgress = lessonProgress => ({
  type: ADD_LESSON_PROGRESS,
  payload: lessonProgress,
});

const readProgress = lessonProgress => ({
  type: READ_LESSON_PROGRESS,
  payload: lessonProgress,
});

const readProgresses = lessonProgresses => ({
  type: READ_LESSON_PROGRESSES,
  payload: lessonProgresses,
});

const deleteLessonProgresses = courseId => ({
  type: DELETE_LESSON_PROGRESSES,
  payload: courseId,
});

const deleteLProgress = lessonId => ({
  type: DELETE_LESSON_PROGRESS,
  payload: lessonId,
});

const deleteCProgress = courseId => ({
  type: DELETE_COURSE_PROGRESS,
  payload: courseId,
});

// @VisibleForTesting
export const saveProgressOnServer = lessonProgress => {
  return (dispatch, getState) => {
    const {
      learnProgress: { lessons },
    } = getState();
    if (userIsLoggedIn(getState())) {
      const progress = findExistingLesson({ lessons, lessonProgress });
      return progress ? saveLessonProgress(progress) : Promise.resolve('no progress available');
    }
    return Promise.resolve('nothing to do');
  };
};

// @VisibleForTesting
export const deleteProgressOnServer = (contentId, type) => {
  return (dispatch, getState) => {
    if (userIsLoggedIn(getState())) {
      return deleteLearnProgress(contentId, type);
    }
    return Promise.resolve('nothing to do');
  };
};

// IMPORTANT: Consider updating storage merger function if this changes
const initialState = { lessons: [], courses: [], isSyncRequired: false };

export const learnProgressReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_LESSON_PROGRESS:
      // eslint-disable-next-line no-case-declarations
      const lessonProgress = generateLessonProgress({
        lessons: state.lessons,
        lessonProgress: action.payload,
      }) || { lessons: [] };
      return {
        ...state,
        ...lessonProgress,
      };
    case READ_LESSON_PROGRESS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...updateLessonProgressFromService({
          lessons: state.lessons,
          lessonProgress: action.payload,
        }),
      };
    case READ_LESSON_PROGRESSES:
      return {
        ...state,
        ...initializeLessonLearnProgressWithDataFromServer({
          lessonProgresses: action.payload,
        }),
      };
    case DELETE_LESSON_PROGRESS:
      return {
        ...state,
        ...deleteLessonProgressById({
          lessons: state.lessons,
          lessonId: action.payload,
        }),
      };
    case READ_COURSE_PROGRESS:
      return {
        ...state,
        ...addCourseProgressData({ courses: state.courses, courseProgress: action.payload }),
      };
    case DELETE_COURSE_PROGRESS:
      return {
        ...state,
        ...deleteCourseProgressById({
          courses: state.courses,
          courseId: action.payload,
        }),
      };
    case DELETE_LESSON_PROGRESSES:
      return {
        ...state,
        ...deleteLessonProgressesByCourseId({ lessons: state.lessons, courseId: action.payload }),
      };
    case LESSON_PROGRESS_SYNC_NEEDED:
      return {
        ...state,
        isSyncRequired: action.payload,
      };
    case REMOVE_LEARN_PROGRESS:
      return initialState;
    default:
      return state;
  }
};
