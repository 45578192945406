export const LOAD = 'REDUX_STORAGE_LOAD';

export const reducerFunc = reducer => (state, action) =>
  // IMPORTANT: Doing a shallow merge here may have to be reconsidered if initial state changes
  reducer(action.type === LOAD ? { ...state, ...action.payload } : state, action);

export const loadStorage = (engine, store) => {
  engine.load().then(newState => store.dispatch({ type: LOAD, payload: newState }));
};

export const createMiddleware =
  engine =>
  ({ getState }) =>
  next =>
  action => {
    const result = next(action);

    if (typeof action === 'object' && action !== null && action.type !== LOAD) {
      engine.save(getState());
    }

    return result;
  };
