import styled, { css } from 'styled-components';
import { colors, fontSize } from '../../utils/css';

export const TeaserHeadline = styled('h3')`
  ${fontSize.plus1}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const ContentBlockHeadline = styled('h2')`
  ${fontSize.plus1}
  color: ${({ theme }) => (theme.dark ? colors.DW_LIGHT_BLUE_NEW : colors.BLUE_GREY_04)};
  line-height: 1.1;
  font-weight: 500;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const NavigationSectionHeadline = styled('h3')`
  ${fontSize.minus1}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_02 : colors.DARK_BLUE_GREY_02)};
  line-height: 1.1;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-variation-settings: 'opsz' 20;
  text-transform: uppercase;
  hyphens: auto;
`;

export const DetailPageHeadline = styled('h1')`
  ${fontSize.plus4}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DW_DARK_BLUE_NEW)};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const OverviewPageHeadline = styled('h1')`
  ${fontSize.plus4}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_03 : colors.BLUE_GREY_04)};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

// These are applied with descendent selectors
export const richTextHeadlineStyles = css`
  ${fontSize.plus2}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const RichTextHeadline = styled('h2')(...richTextHeadlineStyles);

export const richTextSubHeadlineStyles = css`
  ${fontSize.plus1}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const LessonMenuHeadline = styled('h1')`
  ${fontSize.plus1}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;
