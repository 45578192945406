import { isServer } from './ssr';
import globals from './globals';

export const noop = () => null;

export const invert = inMap =>
  Object.entries(inMap).reduce((acc, next) => ({ ...acc, [next[1]]: next[0] }), {});

export const not = predicate => args => !predicate(args);

export const pipe = (...fns) =>
  fns.reduce(
    (v, f) =>
      (...args) =>
        f(v(...args)),
  );

// a functionality that groups a collection of items together into an object based on a given key
export const groupBy = (key, array) =>
  array.reduce(
    (acc, next) => Object.assign(acc, { [next[key]]: (acc[next[key]] || []).concat(next) }),
    {},
  );

export const removeContentLinksWithEmptyTarget = contentLinks =>
  contentLinks.filter(contentLink => !!contentLink.target);

export const isFinalTestPredicate = contentLink =>
  contentLink.additionalInformation && contentLink.additionalInformation.includes('final_test');

export const capitalize = text => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const getMediaFileSizeInKBorMB = fileSizeInBytes =>
  fileSizeInBytes > 999999
    ? `${Math.floor(fileSizeInBytes / 1000000)}MB`
    : `${Math.floor(fileSizeInBytes / 1000)}KB`;

export const shuffle = array => {
  // eslint-disable-next-line
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const swapValue = array[i];
    array[i] = array[j]; // eslint-disable-line no-param-reassign
    array[j] = swapValue; // eslint-disable-line no-param-reassign
  }
  return array;
};

export const safeShuffle = array => shuffle([...array]);

export const calculatePercentage = (fraction, total) => Math.round((fraction / total) * 100) || 0;

export const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const sortObjectsByProp = (array, prop, order = 1) => {
  return [...array].sort((a, b) => {
    if (a[prop] === b[prop]) {
      return 0;
    }
    return a[prop] < b[prop] ? -order : order;
  });
};

const vowels = /** @type {const} */ ([
  'A, a',
  'Ä, ä',
  'E, e',
  'I, i',
  'O, o',
  'Ö, ö',
  'U, u',
  'Ü, ü',
  'Eu, eu',
  'Au, au',
  'ie',
  'Ei, ei',
]);

/**
 * @param {unknown} shortTitle
 * @returns {shortTitle is vowels[number]}
 */
export function isVowelTitle(shortTitle) {
  return vowels.includes(shortTitle);
}

export const linkHasValidTarget = link => !!link.target;

export const linkIsNotInText = link => !link.isInText;

export const isSafari = () =>
  !isServer() &&
  globals.window.navigator.userAgent.toLowerCase().indexOf('safari/') > -1 &&
  globals.window.navigator.userAgent.toLowerCase().indexOf('chrome/') === -1; // chrome also has 'safari' in its agent
