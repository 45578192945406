export const SET_IMPROVE_MODE_FOR_LESSON = 'SET_IMPROVE_MODE_FOR_LESSON';
export const REMOVE_IMPROVE_MODE_FOR_LESSON = 'REMOVE_IMPROVE_MODE_FOR_LESSON';

export const LESSON_EXERCISE_MODE = Object.freeze({
  IMPROVE: 'IMPROVE',
});

// actions
export const setImproveMode = cosLessonId => ({
  type: SET_IMPROVE_MODE_FOR_LESSON,
  payload: cosLessonId,
});

export const removeImproveMode = cosLessonId => ({
  type: REMOVE_IMPROVE_MODE_FOR_LESSON,
  payload: cosLessonId,
});

// helpers
const findExistingLesson = ({ cosLessonId, lessons }) => {
  return lessons.find(lesson => lesson.cosLessonId === cosLessonId);
};

const addLessonMode = ({ cosLessonId, lessons }) => {
  const existingLesson = findExistingLesson({ cosLessonId, lessons });
  if (existingLesson) {
    const otherLessons = lessons.filter(lesson => lesson.cosLessonId !== cosLessonId);
    return [...otherLessons, { cosLessonId, mode: LESSON_EXERCISE_MODE.IMPROVE }];
  }
  return [...lessons, { cosLessonId, mode: LESSON_EXERCISE_MODE.IMPROVE }];
};

const removeLessonMode = ({ cosLessonId, lessons }) => {
  const existingLesson = findExistingLesson({ cosLessonId, lessons });
  if (existingLesson) {
    const otherLessons = lessons.filter(lesson => lesson.cosLessonId !== cosLessonId);
    return [...otherLessons];
  }
  return [...lessons];
};

// IMPORTANT: Consider updating storage merger function if this changes
const initialState = [];

// reducer
export const lessonExerciseModeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_IMPROVE_MODE_FOR_LESSON:
      return addLessonMode({ cosLessonId: action.payload, lessons: state });
    case REMOVE_IMPROVE_MODE_FOR_LESSON:
      return removeLessonMode({ cosLessonId: action.payload, lessons: state });
    default:
      return state;
  }
};
