import styled, { css } from 'styled-components';
import { colors, fontSize, mediaMin } from '../../utils/css';

export const TeaserText = styled('span')`
  ${fontSize.base}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_01 : colors.DARK_BLUE_GREY_02)};
  line-height: 1.4;
`;

export const exerciseBubbleTextStyles = css`
  font-size: 16px;
  ${mediaMin.xl`
    font-size: 17px;
  `};
  line-height: 1.4;
`;

export const DetailIntroText = styled('p')`
  ${fontSize.plus1}
  color: ${({ theme }) => (theme.dark ? colors.BLUE_GREY_01 : colors.DARK_BLUE_GREY_02)};
  line-height: 1.4;
  font-weight: 500;
`;

export const detailRichtextTextStyles = css`
  ${fontSize.base}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.4;
`;

export const DetailRichtextText = styled('p')(...detailRichtextTextStyles);

export const VocabularyItemText = styled('p')`
  ${fontSize.base}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.4;
`;

export const CaptionText = styled('span')`
  ${fontSize.minus1}
  color: ${({ theme }) => (theme.dark ? colors.LG_WHITE : colors.DARK_BLUE_GREY_01)};
  line-height: 1.4;
`;
