import { createContext, useContext, useMemo } from 'react';
import { replaceInPattern, t } from '../utils/i18n';

// @used in ROAD
export const I18nContext = createContext(
  // Just use the key themselves as translations for testing
  process.env.NODE_ENV === 'test' ? { i18n: { t: replaceInPattern } } : undefined,
);

export const I18nProvider = ({ children, translations }) => {
  const value = useMemo(
    () => ({ i18n: { t: (key, options) => t(translations, key, options) } }),
    [translations],
  );

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};

export const I18nStringProvider = ({ children, translationsString }) => {
  const translations = useMemo(
    () => (typeof translationsString !== 'string' ? null : JSON.parse(translationsString)),
    [translationsString],
  );

  return <I18nProvider translations={translations}>{children}</I18nProvider>;
};

export const useI18nContext = () => useContext(I18nContext);
