import { combineReducers } from 'redux';
import { userReducer } from './user/userDuck';
import { learnProgressReducer } from './progress/learnProgressDuck';
import { lessonExerciseModeReducer } from './mode/lessonExerciseModeDuck';

const rootReducer = combineReducers({
  user: userReducer,
  learnProgress: learnProgressReducer,
  lessonExerciseMode: lessonExerciseModeReducer,
});

export default rootReducer;
