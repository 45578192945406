export const memoizeConst = fn => {
  let innerFn = () => {
    const result = fn();
    innerFn = () => result;
    return result;
  };

  return () => innerFn();
};

export const memoizeWith = (keyGen, fn) => {
  const cache = {};
  return (...args) => {
    const key = keyGen(...args);

    if (!(key in cache)) {
      cache[key] = fn(...args);
    }

    return cache[key];
  };
};
