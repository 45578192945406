import { LANG_CODES } from './langMapper';

const rtlLanguages = [
  LANG_CODES.ARABIC,
  LANG_CODES.DARI,
  LANG_CODES.HAUSA,
  LANG_CODES.PASHTO,
  LANG_CODES.PERSIAN,
  LANG_CODES.URDU,
];
export const isRtlByLangCode = langCode => rtlLanguages.includes(langCode);
