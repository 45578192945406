import { createGlobalStyle } from 'styled-components';

import {
  PangeaArabic,
  PangeaCyrillic,
  PangeaGreek,
  PangeaLatinBasicLetters,
  PangeaLatinBasicSymbols,
  PangeaLatinExtended,
} from '../../assets/fonts/Pangea/PangeaFonts';
import {
  NotoNaskhArabic,
  NotoSansLatinBasicLetters,
  NotoSansLatinBasicSymbols,
  NotoSansLatinExtended,
} from '../../assets/fonts/NotoSans/NotoSansFonts';
import { useLanguage } from '../../context/LanguageContext';

const fontFaceBaseTemplate = ({ fontWeight, family, unicodeRange }) => `
  font-display : swap;
  font-family  : ${family};
  font-style   : normal;
  font-weight  : ${fontWeight};
  unicode-range: ${unicodeRange};
`;

export const pangeaFontFaces = [
  /* DW Pangea VAR */
  /* --- Albanian, Bosnian, Bulgarian, Croatian, English, 
    French, German, Greek, Hausa, Indonesian,
    Kiswahili, Macedonian, Polish, Portuguese, Romanian, 
    Russian, Serbian, Spanish, Turkish, Ukrainian ---*/
  {
    font: PangeaLatinBasicLetters,
    unicodeRanges: ['U+041-05A', 'U+061-07A'],
  } /* Basic Latin - Letters */,
  {
    font: PangeaLatinBasicSymbols,
    unicodeRanges: ['U+020-040', 'U+05B-060', 'U+07B-07E', 'U+0A0', 'U+0A9'],
  } /* Basic Latin - Symbols (+ nbsp, + ©) */,
  {
    font: PangeaLatinExtended,
    unicodeRanges: ['U+0A1-0A8', 'U+0AA-24F'],
  } /* Latin-1 Supplement + Latin Extended-A + Latin Extended-B */,
  { font: PangeaGreek, unicodeRanges: ['U+370-3FF'] } /* Greek and Coptic */,
  { font: PangeaCyrillic, unicodeRanges: ['U+400-52F'] } /* Cyrillic + Cyrillic Supplement */,
  /* --- Arabic, Dari, Pashto, Persian, Urdu ---*/
  {
    font: PangeaArabic,
    unicodeRanges: ['U+600-6FF', 'U+750-77F', 'U+8A0-8FE', 'U+FB50-FDFF', 'U+FE70-FEFF'],
  } /* Arabic + Arabic Supplement + Arabic Extended-A + 
    Arabic Presentation Forms-A + Arabic Presentation Forms-B */,
];

// ----- PANGEA -----
export const generatePangeaFontFace = ({ font, unicodeRanges }) => {
  const unicodeRange = unicodeRanges.join();
  return `
    @font-face {
        ${fontFaceBaseTemplate({ family: 'Pangea', fontWeight: '300 700', unicodeRange })}
        font-optical-sizing: none;
        src:  local('DW Pangea VAR Light'),
              url(${font.all}) format('woff2 supports variations'),
              url(${font.all}) format('woff2-variations');
    }`;
};

// ----- NOTOSANS -----

export const notoSansFontFaces = [
  /* NotoSans */
  /* --- Albanian, Bosnian, Bulgarian, Croatian, English, 
    French, German, Greek, Hausa, Indonesian,
    Kiswahili, Macedonian, Polish, Portuguese, Romanian, 
    Russian, Serbian, Spanish, Turkish, Ukrainian ---*/
  {
    font: NotoSansLatinBasicLetters,
    unicodeRanges: ['U+041-05A', 'U+061-07A'],
  } /* Basic Latin - Letters */,
  {
    font: NotoSansLatinBasicSymbols,
    unicodeRanges: ['U+020-040', 'U+05B-060', 'U+07B-07E', 'U+0A0', 'U+0A9'],
  } /* Basic Latin - Symbols (+ nbsp, + ©) */,
  {
    font: NotoSansLatinExtended,
    unicodeRanges: ['U+0A1-0A8', 'U+0AA-24F'],
  } /* Latin-1 Supplement + Latin Extended-A + Latin Extended-B */,
  // { font: NotoSansGreek, unicodeRanges: ['U+370-3FF'] }, /* Greek and Coptic */
  // { font: NotoSansCyrillic, unicodeRanges: ['U+400-52F'] }, /* Cyrillic + Cyrillic Supplement */
  // /* --- Amharic ---*/
  // { font: NotoSansEthiopic, unicodeRanges: ['U+1200-137F'], src: 'Noto Sans Ethiopic' },
  // /* Ethiopic */
  /* --- Arabic, Dari, Pashto, Persian, Urdu ---*/
  {
    font: NotoNaskhArabic,
    unicodeRanges: ['U+600-6FF', 'U+750-77F', 'U+8A0-8FE', 'U+FB50-FDFF', 'U+FE70-FEFF'],
    src: 'Noto Naskh Arabic',
  } /* Arabic + Arabic Supplement + Arabic Extended-A + Arabic Presentation Forms-A + 
       Arabic Presentation Forms-B */,
  // /* --- Bengali ---*/
  // { font: NotoSansBengali, unicodeRanges: ['U+980-9FF'], src: 'Noto Sans Bengali' },
  // /* Bengali */
  // /* --- Hindi ---*/
  // { font: NotoSansDevanagari, unicodeRanges: ['U+900-97F'], src: 'Noto Sans Devanagari' },
  // /* Devanagari */
];

export const generateNotoSansFontFace = ({ font, src = 'Noto Sans', unicodeRanges }) => {
  const unicodeRange = unicodeRanges.join();
  return `   
    @font-face {
        ${fontFaceBaseTemplate({ family: 'NotoSans', fontWeight: '400', unicodeRange })}
        src:  local('${src}'),
              url(${font.regular}) format('woff2');
    }
    @font-face {
        ${fontFaceBaseTemplate({ family: 'NotoSans', fontWeight: '700', unicodeRange })}
        src:  local('${src}'),
              url(${font.bold}) format('woff2');
    }
  `;
};

const PangeaFontFaces = createGlobalStyle`
  ${pangeaFontFaces.map(generatePangeaFontFace).join('')}

  * {
    font-family: Pangea, sans-serif;
    font-variation-settings: 'opsz' 10;
  }
`;

const NotoFontFaces = createGlobalStyle`
  ${notoSansFontFaces.map(generateNotoSansFontFace).join('')}

  * {
    font-family: NotoSans, sans-serif;
  }
`;

export const GlobalFonts = () => {
  const { langCode } = useLanguage();

  if (langCode === 'fa-ir') {
    return <NotoFontFaces />;
  }

  return <PangeaFontFaces />;
};
