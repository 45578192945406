import styled from 'styled-components';
import { useChineseSimpleTraditionalSwitch } from './useChineseSimpleTraditionalSwitch';
import { useNavigationContext } from './NavigationContainer/NavigationContext.jsx';
import { ButtonInteraction } from '../Interaction/Interaction.jsx';

// @VisibleForTesting
export const toggleButtonId = 'zh-convert';

export const ChineseVariantSwitch = ({ className }) => {
  const { isTraditionalChin } = useNavigationContext();
  const { trans2Simple, trans2Traditional } = useChineseSimpleTraditionalSwitch(toggleButtonId);

  const transFunction = isTraditionalChin ? trans2Simple : trans2Traditional;

  return <ButtonInteraction id={toggleButtonId} className={className} onClick={transFunction} />;
};

const buttonPadding = '10px'; // enlarges clickable area

export const StyledChineseVariantSwitch = styled(ChineseVariantSwitch)`
  background-color: transparent;
  padding: ${buttonPadding};
  /*! @noflip */
  border: none;
  cursor: pointer;
`;
