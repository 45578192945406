import { useI18nContext } from '../context/I18nContext.jsx';
import { createTitle, PAGE_TYPE_TO_TITLE_TRANSLATION_KEYS } from '../utils/pageMetadata';

export const useMetaTitle = ({
  pageType,
  translationFirst,
  contentName,
  parentContentName,
} = {}) => {
  const { i18n } = useI18nContext();
  const pageTitleTranslationKeys = PAGE_TYPE_TO_TITLE_TRANSLATION_KEYS[pageType];
  const pageTitleTranslations = pageTitleTranslationKeys
    ? pageTitleTranslationKeys.map(key => i18n.t(key))
    : [];

  const titleParts = translationFirst
    ? [...pageTitleTranslations, contentName, parentContentName]
    : [contentName, ...pageTitleTranslations, parentContentName];

  return createTitle({ titleParts });
};
