export const FEEDBACK_TYPES = {
  OTHER: 'OTHER',
  LOGIN: 'LOGIN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_FB: 'LOGIN_FB',
  LOGOUT: 'LOGOUT',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  PROFILE_SERVICE_UNAVAILABLE: 'PROFILE_SERVICE_UNAVAILABLE',
  MISSING_USER_PROFILE: 'MISSING_USER_PROFILE',
  FORCED_LOGOUT: 'FORCED_LOGOUT',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  MAIL_CHANGED: 'MAIL_CHANGED',
  MAIL_CHANGE_REQUEST: 'MAIL_CHANGE_REQUEST',
  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  REGISTERED: 'REGISTERED',
  REGISTRATION_CONFIRMED: 'REGISTRATION_CONFIRMED',
  DELETED: 'DELETED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  PASSWORD_RESET_REQUESTED: 'PASSWORD_RESET_REQUESTED',
  PASSWORD_RESET_SET: 'PASSWORD_RESET_SET',
  PASSWORD_CHANGE_WRONG_PASSWORD: 'PASSWORD_CHANGE_WRONG_PASSWORD',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE_',
  INVALID_CERTIFICATE: 'INVALID_CERTIFICATE',
  VOCABULARY_TRAINER_UNAVAILABLE: 'VOCABULARY_TRAINER_UNAVAILABLE',
  VOCABULARY_TRAINER_NO_VOCABULARIES: 'VOCABULARY_TRAINER_NO_VOCABULARIES',
  CERTIFICATE_NO_TRANSCRIPTION: 'CERTIFICATE_NO_TRANSCRIPTION',
  MISSING_TRANSCRIPTION: 'MISSING_TRANSCRIPTION',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  USER_ALREADY_CONFIRMED: 'USER_ALREADY_CONFIRMED',
  RECAPTCHA_EXCEPTION: 'RECAPTCHA_EXCEPTION',
  USER_NOT_EXISTS: 'USER_NOT_EXISTS',
  EMAIL_CHANGE_TOKEN_NOT_EXISTS: 'EMAIL_CHANGE_TOKEN_NOT_EXISTS',
};

export const TYPES_WITH_PROFILE_PAGE_AS_TARGET = [
  FEEDBACK_TYPES.PASSWORD_CHANGE_WRONG_PASSWORD,
  FEEDBACK_TYPES.PASSWORD_CHANGED,
  FEEDBACK_TYPES.EMAIL_ALREADY_EXISTS,
  FEEDBACK_TYPES.PROFILE_UPDATED,
  FEEDBACK_TYPES.CERTIFICATE_NO_TRANSCRIPTION,
];

export const TYPES_WITH_HOME_PAGE_AS_TARGET = [
  FEEDBACK_TYPES.OTHER,
  FEEDBACK_TYPES.MAIL_CHANGED,
  FEEDBACK_TYPES.MAIL_CHANGE_REQUEST,
  FEEDBACK_TYPES.REGISTERED,
  FEEDBACK_TYPES.REGISTRATION_CONFIRMED,
  FEEDBACK_TYPES.PASSWORD_RESET_REQUESTED,
  FEEDBACK_TYPES.PASSWORD_RESET_SET,
  FEEDBACK_TYPES.USER_ALREADY_EXISTS,
  FEEDBACK_TYPES.USER_NOT_EXISTS,
];

export const COMMON_FEEDBACK_TRANSLATION_KEYS = {
  TITLE: 'common.feedback',
  OK_BUTTON: 'common.ok',
};
