import { BASIC_JSON_CONTENT_TYPE_HEADER } from '../constants/services';
import { USER } from '../constants/userUrl';

const COURSE_PROGRESS = 'course/progress';
const LESSON_PROGRESS = 'lesson/progress';
const LESSON_PROGRESSES = 'lesson/progresses';
const PROTECTED_URL = `${USER.PS_URL_WITH_DOMAIN}protected`;

export const LESSON_TYPE = 'lesson';
export const COURSE_TYPE = 'course';

const handleError = response =>
  console.error(
    `Something went wrong on service call: ${response.url}, status code: ${response.status}`,
  );

export const getProgress = (id, type) => {
  const contentType = type.toLowerCase();
  return fetch(`${PROTECTED_URL}/${contentType}/progress/${id}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  })
    .then(response => (response.ok ? response.text() : Promise.reject(response)))
    .then(text => (text ? JSON.parse(text) : null))
    .catch(handleError);
};

export const getProgresses = (ids, type) => {
  const contentType = type.toLowerCase();
  return fetch(`${PROTECTED_URL}/${contentType}/progress`, {
    method: 'POST',
    body: JSON.stringify({ ids }),
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  })
    .then(response => response.json())
    .catch(handleError);
};

export const deleteLearnProgress = (id, type) => {
  const contentType = type.toLowerCase();
  return fetch(`${PROTECTED_URL}/${contentType}/progress/reset/${id}`, {
    method: 'DELETE',
  }).catch(handleError);
};

export const saveLessonProgress = lessonProgressData => {
  return fetch(`${PROTECTED_URL}/${LESSON_PROGRESS}/save`, {
    method: 'POST',
    body: JSON.stringify(lessonProgressData),
    dataType: 'json',
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  }).catch(handleError);
};

export const saveLessonProgresses = lessonProgressesData => {
  return fetch(`${PROTECTED_URL}/${LESSON_PROGRESSES}/save`, {
    method: 'POST',
    body: JSON.stringify(lessonProgressesData),
    dataType: 'json',
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  }).catch(handleError);
};

export const getLastEditedLessonsIds = (numberOfLessons, language) => {
  return fetch(`${PROTECTED_URL}/lesson?last=${numberOfLessons}&lang=${language}`, {
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  })
    .then(response => response.json())
    .catch(handleError);
};

export const initCourseProgress = initialCourseProgressData => {
  return fetch(`${PROTECTED_URL}/${COURSE_PROGRESS}/init`, {
    method: 'POST',
    body: JSON.stringify(initialCourseProgressData),
    ...BASIC_JSON_CONTENT_TYPE_HEADER,
  })
    .then(response => response.json())
    .catch(handleError);
};
