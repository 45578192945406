import { FEEDBACK_TYPES } from '../../constants/feedback';
import config from '../../config';

export const PAGE_OVERVIEW = '/overview';
export const PAGE_REGISTRATION = '/user/register';
export const PAGE_PROFILE = '/user/profile';
export const PAGE_PASSWORD_CHANGE = '/user/password/change';
export const PAGE_PASSWORD_RESET = '/user/password/reset';
export const PAGE_VOCABULARY_TRAINER = '/user/vocabularyTrainer';
export const PAGE_VOCABULARY_TRAINER_START = '/user/vocabularyTrainerStart';
export const STATUS_FEEDBACK = '/user/feedback/status';
export const PAGE_LOGIN = '/user/login';
export const PAGE_GRAMMAR = '/grammar';
const PAGE_HELP = '/help';
export const PAGE_VOCABULARY = '/vocabulary';
export const PAGE_PLACEMENT_TEST = '/placementDashboard';
export const EXERCISES_RESULT_URL_SUFFIX = 'ler';
const COURSE_PREFIX = 'c-';
const LESSON_PREFIX = 'l-';
const EXERCISE_PREFIX = 'e-';
const LESSON_GRAMMAR_URL_SUFFIX = 'gr-';
export const LESSON_VOCABULARY_URL_SUFFIX = 'lv';
const LESSON_REGIONAL_STUDIES_SUFFIX = 'rs-';
export const ALPHABETICAL_ORDER_URL_SUFFIX = 'la';
export const LESSON_MANUSCRIPT_SUFFIX = 'lm';
export const LESSON_EXTRAS_SUFFIX = 'le';
export const LESSON_EXERCISE_RESULT_SUFFIX = 'ler';
export const LESSON_SUMMARY_SUFFIX = 'sum';
const SUMMARY_URL_SUFFIX = 'sum';

export const getDashboardUrl = langCode => (langCode ? `/${langCode}${PAGE_OVERVIEW}` : '/');

export const getGrammarUrl = langCode => `/${langCode}${PAGE_GRAMMAR}`;

export const getVocabularyPageUrl = langCode => `/${langCode}${PAGE_VOCABULARY}`;

export const getPlacementDashboardUrl = langCode => `/${langCode}${PAGE_PLACEMENT_TEST}`;

export const getLessonExerciseUrl = (languageCode, lessonId, normalizedUrlName, lessonPartId) =>
  `${getLessonUrl(lessonId, languageCode, normalizedUrlName)}/${EXERCISE_PREFIX}${lessonPartId}`;

export const getLessonGrammarUrl = (languageCode, lessonId, normalizedUrlName, lessonPartId) =>
  `${getLessonUrl(
    lessonId,
    languageCode,
    normalizedUrlName,
  )}/${LESSON_GRAMMAR_URL_SUFFIX}${lessonPartId}`;

export const getLessonPhonemicChartUrl = lessonPath =>
  `${lessonPath}/${ALPHABETICAL_ORDER_URL_SUFFIX}`;

export const getLessonRegionalStudiesUrl = (
  languageCode,
  lessonId,
  normalizedUrlName,
  lessonPartId,
) =>
  `${getLessonUrl(
    lessonId,
    languageCode,
    normalizedUrlName,
  )}/${LESSON_REGIONAL_STUDIES_SUFFIX}${lessonPartId}`;

export const getRegisterUrl = langCode => `/${langCode}${PAGE_REGISTRATION}`;

export const getUserProfileUrl = langCode => `/${langCode}${PAGE_PROFILE}`;

export const getPasswordChangeUrl = langCode => `/${langCode}${PAGE_PASSWORD_CHANGE}`;

export const getPasswordResetUrl = langCode => `/${langCode}${PAGE_PASSWORD_RESET}`;

export const getLoginUrl = langCode => `/${langCode}${PAGE_LOGIN}`;

export const getCourseUrl = (courseId, langCode, normalizedUrlCourseName) =>
  `/${langCode}/${normalizedUrlCourseName}/${COURSE_PREFIX}${courseId}`;

export const getLessonUrl = (lessonId, langCode, normalizedUrlLessonName) =>
  `/${langCode}/${normalizedUrlLessonName}/${LESSON_PREFIX}${lessonId}`;

export const getLessonExercisesResultUrl = lessonUrl =>
  `${lessonUrl}/${EXERCISES_RESULT_URL_SUFFIX}`;

export const getFirstGrammarUrl = (lessonUrl, firstGrammarId) =>
  `${lessonUrl}/gr-${firstGrammarId}`;

export const getVocabularyUrl = lessonUrl => `${lessonUrl}/${LESSON_VOCABULARY_URL_SUFFIX}`;

export const getAlphabeticalOrderUrl = lessonUrl => `${lessonUrl}/${ALPHABETICAL_ORDER_URL_SUFFIX}`;

export const getFirstRegionalStudiesUrl = (lessonUrl, firstRegionalStudiesId) =>
  `${lessonUrl}/${LESSON_REGIONAL_STUDIES_SUFFIX}${firstRegionalStudiesId}`;

export const getManuscriptUrl = lessonUrl => `${lessonUrl}/${LESSON_MANUSCRIPT_SUFFIX}`;

export const getExtrasUrl = lessonUrl => `${lessonUrl}/${LESSON_EXTRAS_SUFFIX}`;

export const getSummaryUrl = lessonUrl => `${lessonUrl}/${SUMMARY_URL_SUFFIX}`;

export const getPlacementTestResultUrl = ({ langCode, id, name }) =>
  `/${langCode}/${name}/placement-${id}`;

export const transformUrlContentPrefix = (lessonPath, type) =>
  lessonPath.replace('/l-', `/${type}-`);

export const createLinkToResultPage = ({ isFromPlacementCourse, isFinalTest, lessonPath }) => {
  if (isFinalTest) return lessonPath.replace('/l-', `/final-`);

  if (isFromPlacementCourse) return lessonPath.replace('/l-', `/placement-`);

  return getLessonExercisesResultUrl(lessonPath);
};

export const getHelpUrl = langCode => `/${langCode}${PAGE_HELP}`;

export const getVocabularyTrainerStartPage = langCode =>
  `/${langCode}${PAGE_VOCABULARY_TRAINER_START}`;

export const getVocabularyTrainerPage = langCode => `/${langCode}${PAGE_VOCABULARY_TRAINER}`;

export const getFeedbackUrl = (type, langCode) => `/${langCode}${STATUS_FEEDBACK}/${type}`;

export const getErrorFeedbackUrl = (err, langCode) =>
  getFeedbackUrl(err?.errorCode || FEEDBACK_TYPES.OTHER, langCode);

export const getFooterLinkForNamedUrl = ({ namedUrl }) => `${config.dw.mobileUrl}${namedUrl}`;

export const getDwUrlWithLang = ({ langCode }) => `${config.dw.baseUrl}/${langCode}`;

export const getDwContactUrl = () => `${config.dw.mobileUrl}/contact`;
