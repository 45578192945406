import { ReactComponent as SmFacebook } from '../components/socialMediaIcons/svg/facebook.svg';
import { ReactComponent as SmInstagram } from '../components/socialMediaIcons/svg/instagram.svg';
import { ReactComponent as SmTwitter } from '../components/socialMediaIcons/svg/twitter.svg';
import { ReactComponent as SmYoutube } from '../components/socialMediaIcons/svg/youtube.svg';
import { ReactComponent as SmTiktok } from '../components/socialMediaIcons/svg/tiktok.svg';

// NOTE: These are loaded in the footer and need to be in the main bundle

export const eagerIcons = {
  facebook: SmFacebook,
  instagram: SmInstagram,
  'x-twitter': SmTwitter,
  youtube: SmYoutube,
  tiktok: SmTiktok,
};
