import { useEffect } from 'react';
import globals from '../utils/globals';

const loadScript = (url, target) =>
  new Promise((resolve, reject) => {
    const tag = globals.document.createElement('script');
    tag.async = false;
    tag.src = url;
    target.appendChild(tag);
    tag.addEventListener('load', resolve, {
      once: true,
    });
    tag.addEventListener('error', reject, {
      once: true,
    });
  });

export const useScripts = (scriptUrls, cb) => {
  useEffect(() => {
    if (scriptUrls.length > 0) {
      const querySelectorForAllScriptTags = scriptUrls.map(
        scriptUrl => `script[src="${scriptUrl}"]`,
      );
      const existingScriptTags = globals.document.querySelectorAll(querySelectorForAllScriptTags);
      if (existingScriptTags.length !== scriptUrls.length) {
        Promise.all(scriptUrls.map(url => loadScript(url, globals.document.body))).then(cb);
      } else {
        cb();
      }
    }
  }, [scriptUrls, cb]);
};
