import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getFeedbackUrl } from '../../utils/url/urlFactory';
import { userIsLoggedIn } from '../../state/user/userSelectors';
import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { FEEDBACK_TYPES } from '../../constants/feedback';
import { useLanguage } from '../../context/LanguageContext';

export const RestrictedRoute = ({ render, component: Component, children, ...restProps }) => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { langCode } = useLanguage();

  return (
    <Route
      render={(...args) => {
        if (isLoggedIn === null) {
          return <LoadingMessage />;
        }

        if (isLoggedIn) {
          if (children) {
            return children;
          }

          if (Component) {
            return <Component {...args[0]} />;
          }

          return render(...args);
        }

        return <Redirect to={getFeedbackUrl(FEEDBACK_TYPES.MISSING_USER_PROFILE, langCode)} />;
      }}
      {...restProps}
    />
  );
};
