import { description } from './userState';

export const userSelector = state => state.user;
export const userDataSelector = state => state.user.userData || {};

/**
 * @returns {boolean | null} null if app has just started and login is not determined yet.
 */
export const userIsLoggedIn = ({ user: { stateDescription } }) => {
  if ([description.UNDETERMINED].includes(stateDescription)) {
    return null;
  }
  return [description.LOGGED_IN, description.UPDATING_USER, description.DELETING_USER].includes(
    stateDescription,
  );
};

export const userLoading = ({ user: { stateDescription } }) =>
  [
    description.LOGGING_IN,
    description.LOGGING_OUT,
    description.UPDATING_USER,
    description.CONFIRM_USER_EMAIL_CHANGE,
    description.DELETING_USER,
  ].includes(stateDescription);
