import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isServer } from '../utils/ssr';
import globals from '../utils/globals';

export const useScrollToTop = () => {
  const { pathname } = useLocation();
  const { action } = useHistory();

  useEffect(() => {
    if (!isServer() && action !== 'POP') {
      globals.window.scrollTo(0, 0);
    }
  }, [action, pathname]);
};
