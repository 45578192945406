import { Redirect } from 'react-router-dom';
import { Component } from 'react';
import { getFeedbackUrl } from '../../utils/url/urlFactory';
import { FEEDBACK_TYPES } from '../../constants/feedback';
import { LanguageContext } from '../../context/LanguageContext';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <LanguageContext.Consumer>
          {({ langCode }) => <Redirect to={getFeedbackUrl(FEEDBACK_TYPES.OTHER, langCode)} />}
        </LanguageContext.Consumer>
      );
    }

    return this.props.children;
  }
}
